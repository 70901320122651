// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Скрыть полосу прокрутки по умолчанию */
.scroll-container {
    overflow-y: scroll;
    scrollbar-width: thin; /* Для Firefox (обеспечивает тонкую полосу прокрутки) */
    scrollbar-color: transparent transparent; /* Полоса и фон скрыты */
}

/* Показать полосу прокрутки только при наведении */
.scroll-container:hover {
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0); /* Цвет полосы прокрутки */
}

/* Для Webkit-браузеров (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
    width: 6px; /* Устанавливаем ширину полосы прокрутки */
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Цвет полосы прокрутки */
    border-radius: 3px; /* Скругление углов */
}

.scroll-container::-webkit-scrollbar-track {
    background-color: transparent; /* Прозрачный фон */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Chat/styleScroll.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;IACI,kBAAkB;IAClB,qBAAqB,EAAE,uDAAuD;IAC9E,wCAAwC,EAAE,wBAAwB;AACtE;;AAEA,mDAAmD;AACnD;IACI,oDAAoD,EAAE,0BAA0B;AACpF;;AAEA,0CAA0C;AAC1C;IACI,UAAU,EAAE,0CAA0C;AAC1D;;AAEA;IACI,oCAAoC,EAAE,0BAA0B;IAChE,kBAAkB,EAAE,qBAAqB;AAC7C;;AAEA;IACI,6BAA6B,EAAE,mBAAmB;AACtD","sourcesContent":["/* Скрыть полосу прокрутки по умолчанию */\n.scroll-container {\n    overflow-y: scroll;\n    scrollbar-width: thin; /* Для Firefox (обеспечивает тонкую полосу прокрутки) */\n    scrollbar-color: transparent transparent; /* Полоса и фон скрыты */\n}\n\n/* Показать полосу прокрутки только при наведении */\n.scroll-container:hover {\n    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0); /* Цвет полосы прокрутки */\n}\n\n/* Для Webkit-браузеров (Chrome, Safari) */\n.scroll-container::-webkit-scrollbar {\n    width: 6px; /* Устанавливаем ширину полосы прокрутки */\n}\n\n.scroll-container::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.5); /* Цвет полосы прокрутки */\n    border-radius: 3px; /* Скругление углов */\n}\n\n.scroll-container::-webkit-scrollbar-track {\n    background-color: transparent; /* Прозрачный фон */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

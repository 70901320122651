import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = {
  getChannels: () => {
    return axios.get(`${API_BASE_URL}/channels`);
  },

  getDialogs: (channelId) => {
    return axios.get(`${API_BASE_URL}/channels/${channelId}/dialogs`);
  },

  getDialogInfo: (channelId, dialogId) => {
    return axios.get(`${API_BASE_URL}/channels/${channelId}/dialogs/${dialogId}`);
  },

  getMessages: (channelId, dialogId) => {
    return axios.get(`${API_BASE_URL}/channels/${channelId}/dialogs/${dialogId}/messages`);
  },

  getClient: (clientId) => {
    return axios.get(`${API_BASE_URL}/clients/${clientId}`);
  },

  getClients: () => {
    return axios.get(`${API_BASE_URL}/clients`);
  },


  sendMessage: async (channelId, dialogId, messageText, file = null) => {
    const formData = new FormData();
    if (messageText) formData.append('message', messageText);
    if (file) formData.append('file', file);
  
    try {
      const response = await axios.post(`${API_BASE_URL}/channels/${channelId}/dialogs/${dialogId}/messages/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Сообщение успешно отправлено:', response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error('Ошибка при отправке сообщения:', error.response.data);
      } else {
        console.error('Ошибка при отправке сообщения:', error.message);
      }
      throw error;
    }
  },

  updateScenarioStatus: async (channelId, dialogId, needBotAnswer) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/channels/${channelId}/dialogs/${dialogId}/update`, {
        need_bot_answer: needBotAnswer,
      });
      console.log('Статус сценария успешно обновлен:', response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error('Ошибка при обновлении статуса сценария:', error.response.data);
      } else {
        console.error('Ошибка при обновлении статуса сценария:', error.message);
      }
      throw error;
    }
  },

  markMessagesAsViewed: async (channelId, dialogId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/channels/${channelId}/dialogs/${dialogId}/messages/mark-viewed`);
      return response.data;
    } catch (error) {
      console.error('Ошибка при отметке сообщений как просмотренных:', error);
      throw error;
    }
  },

  // Метод для обновления настроек канала
  updateChannelSettings: async (channelId, settings) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/channels/${channelId}/update`, settings, {
        headers: {
          'Authorization': process.env.REACT_APP_BEARER_TOKEN,
          'Content-Type': 'application/json'
        }
      });
      console.log('Настройки канала успешно обновлены:', response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error('Ошибка при обновлении настроек канала:', error.response.data);
      } else {
        console.error('Ошибка при обновлении настроек канала:', error.message);
      }
      throw error;
    }
  },
};

export default api;
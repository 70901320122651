import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { FaDownload, FaEye, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FormResults = () => {
  const { id } = useParams();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedResult, setExpandedResult] = useState(null);
  const [formFields, setFormFields] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Загружаем структуру формы для правильного отображения результатов
        const formResponse = await api.get(`/forms/${id}`);
        const fields = typeof formResponse.data.fields === 'string' 
          ? JSON.parse(formResponse.data.fields) 
          : formResponse.data.fields;
        setFormFields(fields || []);

        // Загружаем список клиентов для отображения имен по client_id
        const clientsResponse = await api.get('/clients');
        setClients(clientsResponse.data);

        // Загружаем результаты
        const resultsResponse = await api.get(`/forms/${id}/results`);
        // Преобразуем поля результатов
        const processedResults = resultsResponse.data.map(result => ({
          ...result,
          fields: typeof result.result === 'string' 
            ? JSON.parse(result.result) 
            : result.result || {}
        }));
        setResults(processedResults);
        console.log('Processed Results:', processedResults); // Отладочный вывод
      } catch (error) {
        console.error('Ошибка при загрузке результатов:', error);
        setError('Не удалось загрузить результаты формы');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const viewResult = async (resultId) => {
    try {
      const response = await api.get(`/forms/${id}/results/${resultId}`);
      console.log('Детальный результат:', response.data);
    } catch (error) {
      console.error('Ошибка при загрузке результата:', error);
    }
  };

  const downloadResults = async () => {
    try {
      const response = await api.get(`/forms/${id}/results/export`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `form-${id}-results.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Ошибка при скачивании результатов:', error);
    }
  };

  const renderFieldValue = (field, value) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    if (field?.type === 'file' && value) {
      return (
        <a 
          href={`https://nikta.ai${value}`} 
          target="_blank" 
          rel="noopener noreferrer"
          className="link link-primary"
        >
          Просмотреть файл
        </a>
      );
    }
    return value || '-';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-error shadow-lg">{error}</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Результаты формы</h2>
       
      </div>

      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th></th>
              <th>ID результата</th>
              <th>Статус</th>
              <th>Дата создания</th>
              <th>Дата обновления</th>
             
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <React.Fragment key={result.id}>
                <tr className="hover">
                  <td>
                    <button
                      onClick={() => setExpandedResult(
                        expandedResult === result.id ? null : result.id
                      )}
                      className="btn btn-ghost btn-sm"
                    >
                      {expandedResult === result.id ? 
                        <FaChevronUp /> : 
                        <FaChevronDown />
                      }
                    </button>
                  </td>
                  <td>{result.id}</td>
                  <td>
                    <span className={`badge ${
                      result.status === 'completed' ? 'badge-success' : 'badge-warning'
                    }`}>
                      {result.status === 'completed' ? 'Завершено' : 'В процессе'}
                    </span>
                  </td>
                  <td>{new Date(result.created_at).toLocaleString()}</td>
                  <td>{new Date(result.updated_at).toLocaleString()}</td>
                 
                </tr>
                {expandedResult === result.id && (
                  <tr>
                    <td colSpan="6">
                      <div className="p-4 bg-base-200 rounded-lg">
                        {Object.entries(result.fields || {}).map(([key, value]) => {
                          const field = formFields.find(f => f.name === key || f.label === key);
                          const fieldTitle = field?.title || field?.label || key;
                          return (
                            <div key={key} className="mb-2">
                              <span className="font-medium">{fieldTitle}: </span>
                              <span>{renderFieldValue(field, value)}</span>
                              {field?.client_id && (
                                <span className="ml-2 italic text-sm text-gray-500">
                                  (Клиент: {clients.find(c => c.id === field.client_id)?.name || 'Неизвестный клиент'})
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FormResults;
import React, { useState, useMemo, useEffect } from 'react';
import { FaTelegramPlane, FaWhatsapp, FaEdit } from 'react-icons/fa';
import { FiLayers, FiSearch, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import axios from 'axios';
import api from './api';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Sidebar = ({
  channels,
  onSelectChannel,
  selectedChannelId,
  onChannelUpdate,
  isCollapsed,
  onToggleCollapse
}) => {
  const [filter, setFilter] = useState('active');
  const [scenarioFilter, setScenarioFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [scenarios, setScenarios] = useState([]);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Состояния для модального окна настроек
  const [editingChannel, setEditingChannel] = useState(null);
  const [editedSettings, setEditedSettings] = useState({});
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  useEffect(() => {
    fetchScenarios();
  }, [channels]);

  const fetchScenarios = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/scenarios`, {
        headers: {
          'Authorization': process.env.REACT_APP_BEARER_TOKEN
        }
      });
      setScenarios(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке сценариев:', error);
      showModal('Ошибка при загрузке сценариев');
    }
  };

  const updateChannelSettings = async (channelId) => {
    try {
      const { scenarioName, limit } = editedSettings;
      const channel = channels.find(channel => channel.id === channelId);
  
      if (!channel) {
        showModal('Канал не найден.');
        return;
      }
  
      const updatedSettings = {
        name: channel.name,
        scenario_name: scenarioName || null,
        limit_llm_messages: limit > 0 ? parseInt(limit) : null,
        bot_integration_id: channel.bot_integration_id || null
      };
  
      console.log('Отправляемые настройки:', updatedSettings);
  
      const response = await api.updateChannelSettings(channelId, updatedSettings);
      
      onChannelUpdate(channelId, updatedSettings);
      showModal(`Настройки канала успешно обновлены.`);
      
      setIsSettingsModalOpen(false);
      setEditingChannel(null);
      setEditedSettings({});
    } catch (error) {
      console.error('Ошибка при обновлении настроек канала:', error.response ? error.response.data : error.message);
      showModal('Ошибка при обновлении настроек канала');
    }
  };

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getChannelIcon = (type) => {
    switch (type) {
      case 'telegram_bot':
      case 'telegram_user_bot':
        return <FaTelegramPlane className="text-blue-500" />;
      case 'whatsapp_business':
      case 'whatsapp_user_bot':
        return <FaWhatsapp className="text-green-500" />;
      default:
        return null;
    }
  };

  const getChannelStyle = (status, isSelected) => {
    let baseStyle = 'hover:bg-base-200 dark:hover:bg-base-700';
    if (isSelected) {
      baseStyle += ' bg-base-200 dark:bg-base-700';
    }
    if (status === 'disabled') {
      baseStyle += ' opacity-50';
    }
    return baseStyle;
  };

  const handleEditClick = (channel) => {
    setEditingChannel(channel);
    setEditedSettings({
      scenarioName: channel.scenario_name || '',
      limit: channel.limit_llm_messages || 0,
    });
    setIsSettingsModalOpen(true);
  };

  const handleCancelSettings = () => {
    setIsSettingsModalOpen(false);
    setEditingChannel(null);
    setEditedSettings({});
  };

  const handleSettingChange = (field, value) => {
    setEditedSettings(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const filteredChannels = useMemo(() => {
    return channels.filter(channel => {
      const matchesFilter = 
        filter === 'all' || 
        (filter === 'active' && channel.integration_status !== 'disabled') ||
        (filter === 'banned' && channel.integration_status === 'disabled');
      
      const matchesScenario = 
        scenarioFilter === 'all' || 
        channel.scenario_name === scenarioFilter;
      
      const matchesSearch = channel.name.toLowerCase().includes(searchQuery.toLowerCase());
      
      return matchesFilter && matchesScenario && matchesSearch;
    });
  }, [channels, filter, scenarioFilter, searchQuery]);

  return (
    <div className={`h-full bg-base-100 flex flex-col relative transition-all duration-300 ${isCollapsed ? 'lg:w-16' : 'w-full'}`}>
      {/* Заголовок */}
      <div className="flex items-center justify-between p-4 border-b border-base-300">
        {(!isCollapsed || window.innerWidth < 1024) && (
          <div className="text-xs w-full mr-0 md:mr-2 font-semibold text-primary-content bg-primary dark:bg-primary p-2 rounded-lg flex flex-row items-center gap-2">
            <FiLayers />
            <span>Каналы</span>
          </div>
        )}
        {window.innerWidth >= 1024 && (
          <button 
            onClick={onToggleCollapse}
            className="btn btn-sm btn-circle ml-auto"
          >
            {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
          </button>
        )}
      </div>

      {/* Основное содержимое */}
      {(!isCollapsed || window.innerWidth < 1024) && (
        <>
          {/* Фильтры и поиск */}
          <div className="p-4 space-y-2">
            <div className="flex items-center bg-base-200 dark:bg-base-800 rounded-lg p-2">
              <FiSearch className="text-base-content mr-2" />
              <input
                type="text"
                placeholder="Поиск каналов..."
                className="bg-transparent border-none focus:outline-none w-full text-base-content text-xs"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <select 
              className="select select-bordered select-sm w-full bg-base-200 dark:bg-base-800 text-base-content text-xs"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">Все каналы</option>
              <option value="active">Активные</option>
              <option value="banned">Забаненные</option>
            </select>
            <select 
              className="select select-bordered select-sm w-full bg-base-200 dark:bg-base-800 text-base-content text-xs"
              value={scenarioFilter}
              onChange={(e) => setScenarioFilter(e.target.value)}
            >
              <option value="all">Все сценарии</option>
              {scenarios.map((scenario, index) => (
                <option key={index} value={scenario}>{scenario}</option>
              ))}
            </select>
          </div>

          {/* Список каналов */}
          <div className="flex-grow overflow-y-auto px-4 text-xs">
            <ul className="space-y-2">
              {filteredChannels.map((channel) => (
                <li key={channel.id}>
                  <div className={`flex items-center justify-between p-3 rounded-md ${getChannelStyle(channel.integration_status, channel.id === selectedChannelId)} transition-colors duration-200 text-xs`}>
                    {/* Информация о канале */}
                    <button
                      onClick={() => onSelectChannel(channel)}
                      className="flex items-center w-full text-left text-xs "
                      aria-label={channel.name}
                    >
                      <span className="text-2xl mr-3">
                        {getChannelIcon(channel.integration_type)}
                      </span>
                      <div className="flex flex-col ">
                        <span className="text-base-content text-xs font-medium mb-2">{channel.name}</span>
                        {channel.integration_status === 'disabled' ? (
                          <div className=" text-xs badge badge-error">Забанен</div>
                        ) : (
                          <div className=" text-xs badge badge-success">Активный</div>
                        )}
                      </div>
                    </button>

                    {/* Кнопка редактирования */}
                    <button
                      onClick={() => handleEditClick(channel)}
                      className="ml-2 text-base-content hover:text-base-content hover:bg-base-300 p-2 rounded-md"
                      title="Редактировать"
                    >
                      <FaEdit className="text-md"/>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      {/* Свернутый сайдбар */}
      {isCollapsed && window.innerWidth >= 1024 && (
        <div className="flex flex-col items-center mt-4">
          {filteredChannels.map((channel) => (
            <div key={channel.id} className="tooltip tooltip-right" data-tip={`
      ${channel.name}
      Тип: ${channel.integration_type}
      Статус: ${channel.integration_status === 'disabled' ? 'Забанен' : 'Активный'}
      Сценарий: ${channel.scenario_name || 'Не выбран'}
      Лимит AI сообщений: ${channel.limit_llm_messages || 'Не ограничен'}
            `}>
              <button
                onClick={() => onSelectChannel(channel)}
                className={`btn btn-circle btn-md mb-2 ${selectedChannelId === channel.id ? 'bg-base-200 dark:bg-base-700' : 'bg-transparent hover:bg-base-200 dark:hover:bg-base-700'}`}
              >
                {getChannelIcon(channel.integration_type)}
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Модальное окно уведомлений */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
          <div className="relative p-8 bg-base-100 dark:bg-base-800 w-full max-w-md m-auto flex-col flex rounded-lg">
            <h3 className="font-bold text-lg text-base-content">Уведомление</h3>
            <p className="py-4 text-base-content">{modalMessage}</p>
            <div className="flex justify-end">
              <button onClick={closeModal} className="btn btn-primary">Закрыть</button>
            </div>
          </div>
        </div>
      )}

      {/* Модальное окно настроек канала */}
      {isSettingsModalOpen && editingChannel && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
          <div className="relative p-6 bg-base-100 dark:bg-base-800 w-full max-w-md m-auto flex-col flex rounded-lg">
            <h3 className="font-bold text-lg text-base-content">Настройки канала: {editingChannel.name}</h3>
            <div className="py-4 space-y-4">
              <div>
                <label className="block text-base-content mb-1">Сценарий</label>
                <select
                  className="select select-bordered w-full bg-base-200 dark:bg-base-700 text-base-content"
                  value={editedSettings.scenarioName}
                  onChange={(e) => handleSettingChange('scenarioName', e.target.value)}
                >
                  <option value="">Без сценария</option>
                  {scenarios.map((scenario, idx) => (
                    <option key={idx} value={scenario}>
                      {scenario}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-base-content mb-1">Лимит AI сообщений</label>
                <input
                  type="number"
                  min="0"
                  placeholder="Лимит LLM сообщений"
                  className="input input-bordered w-full bg-base-200 dark:bg-base-700 text-base-content"
                  value={editedSettings.limit}
                  onChange={(e) => handleSettingChange('limit', parseInt(e.target.value) || 0)}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => updateChannelSettings(editingChannel.id)}
                className="btn btn-success"
                title="Сохранить"
              >
                Сохранить
              </button>
              <button
                onClick={handleCancelSettings}
                className="btn btn-error"
                title="Отменить"
              >
                Отменить
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUser, FaEnvelope, FaCheckCircle, FaIdBadge, FaCalendarAlt, FaEdit } from 'react-icons/fa';
import config from './config';

function Profile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(config.API_PROFILE);
                const data = response.data;
                setProfile(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    if (error) {
        return (
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="text-xl font-semibold text-error">Ошибка: {error.message}</div>
            </div>
        );
    }

    return (
        <div className="flex-1 p-4 overflow-y-auto">
            <div className="mx-auto">
                <h1 className='text-2xl font-bold text-base-content mb-6'>Профиль</h1>
                
                <div className='flex flex-col gap-6'>
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <span className="loading loading-spinner text-primary"></span>
                        </div>
                    ) : (
                        <div className="bg-base-100 dark:bg-base-700 p-6 rounded-lg shadow-md">
                            <div className='flex items-center mb-4'>
                                <FaUser className="text-primary text-2xl mr-2" />
                                <h2 className='text-xl font-semibold text-base-content'>Информация профиля</h2>
                            </div>
                            <div className="flex flex-col md:flex-row gap-6">
                                <div className="flex-shrink-0">
                                    <div className="avatar">
                                        <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                            <img src={profile.avatar || "https://via.placeholder.com/150"} alt="User avatar" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <ProfileItem icon={FaIdBadge} label="ID" value={profile.id} />
                                        <ProfileItem icon={FaUser} label="Имя" value={profile.name} />
                                        <ProfileItem icon={FaEnvelope} label="Email" value={profile.email} />
                                        <ProfileItem 
                                            icon={FaCheckCircle} 
                                            label="Подтвержден Email" 
                                            value={profile.email_verified_at ? 'Да' : 'Нет'}
                                            valueClass={profile.email_verified_at ? 'text-success' : 'text-error'}
                                        />
                                        <ProfileItem icon={FaCalendarAlt} label="Дата регистрации" value={new Date(profile.created_at).toLocaleDateString()} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex justify-end">
                                <button className="btn btn-primary">
                                    <FaEdit className="mr-2" /> Редактировать профиль
                                </button>
                            </div>
                        </div>
                    )}
                    
                    {/* Здесь можно добавить другие секции профиля */}
                    
                </div>
            </div>
        </div>
    );
}

function ProfileItem({ icon: Icon, label, value, valueClass = '' }) {
    return (
        <div className="flex items-center gap-2 p-2 bg-base-200 dark:bg-base-600 rounded-lg">
            <Icon className="text-primary text-xl flex-shrink-0" />
            <div>
                <p className="text-sm text-base-content opacity-70">{label}</p>
                <p className={`text-base font-semibold text-base-content ${valueClass}`}>{value}</p>
            </div>
        </div>
    );
}

export default Profile;
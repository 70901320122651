import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './scss/index.scss'; 
import './css/index.css';
import './animations.css'
import { FaTelegram } from "react-icons/fa6";
import { motion, AnimatePresence } from 'framer-motion';
import { FiX, FiHome, FiUsers, FiMessageSquare, FiFolder } from 'react-icons/fi';
import { RiRobotLine } from 'react-icons/ri';
import { TypeAnimation } from 'react-type-animation';

// Импорт изображений
import logo from './assets/logo.svg';
import headsImage from './assets/heads.png';
import role1Image from './assets/role-1.svg';
import role2Image from './assets/role-2.svg';
import role3Image from './assets/role-3.svg';
import role4Image from './assets/role-4.svg';
import role5Image from './assets/role-5.svg';
import role6Image from './assets/role-6.svg';
import hierarchy1Image from './assets/hierarchy-1.svg';
import hierarchy2Image from './assets/hierarchy-2.svg';
import hierarchy3Image from './assets/hierarchy-3.svg';

import hierarchy1Image1 from './assets/how-it-work-1.svg';
import hierarchy2Image1 from './assets/how-it-work-2.svg';
import hierarchy3Image1 from './assets/how-it-work-3.svg';
import whatsapp from './whatsapp.json'
import Lottie from "lottie-react";
import animationData from './robot.json';
import screens from './screens.json'

import elipse from './assets/ellipse-line.svg'


import tech from './tech.png'

import construct1 from './construct1.jpg'
import mails1 from './mails1.jpg'
import forms1 from './forms1.jpg'
import chat1 from './chat1.jpg'

import robot from './robot.json'

const testimonials = [
  {
    name: "Александр Яцко",
    position: "Специалист, Мосводканал",
    text: "Плотно работаем с никтой, сейчас автоматизируем наш юридический отдел, работы много, потенциал огромный, пока что доволен",
    avatar: `https://ui-avatars.com/api/?name=Александр+Яцко&background=random`
  },
  {
    name: "Александр Орлов",
    position: "Руководитель, MGMLogistic",
    text: "AI-ассистент помогает нам каждый день формировать отчеты для WB и OZON, сильно сэкономили на сотрудниках",
    avatar: `https://ui-avatars.com/api/?name=Александр+Орлов&background=random`
  },
  {
    name: "Алекс",
    position: "СЕО, SIA Konomic",
    text: "Был запрос на разработку AI-ассистента для обработки заявок. Мы решили не тратить время на поиск разработчиков и просто взяли у вас.",
    avatar: `https://ui-avatars.com/api/?name=Алекс&background=random`
  }
];




function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqItems = [
    {
      question: "Что такое AI-ассистент?",
      answer: "AI-ассистент - это программа, использующая искусственный интеллект для выполнения задач и взаимодействия с пользователями, имитируя человеческое общение и принятие решений."
    },
    {
      question: "Как AI-ассистент может помочь моему бизнесу?",
      answer: "AI-ассистент может автоматизировать рутинные задачи, обрабатывать запросы клиентов 24/7, анализировать данные и предоставлять ценные инсайты, что повышает эффективность и снижает затраты."
    },
    {
      question: "Насколько безопасно использовать AI-ассистентов?",
      answer: "Мы уделяем особое внимание безопасности данных. Наши AI-ассистенты разработаны с учетом строгих протоколов безопасности и соответствуют всем необходимым стандартам защиты информации."
    },
    {
      question: "Сколько времени занимает внедрение AI-ассистента?",
      answer: "Время внедрения зависит от сложности задач и объема данных. Обычно базовая версия может быть запущена в течение нескольких недель, а более сложные решения - за 1-3 месяца."
    },
    {
      question: "Можно ли настроить AI-ассистента под специфику моего бизнеса?",
      answer: "Да, мы предлагаем полностью кастомизируемые решения. Наши специалисты настроят AI-ассистента с учетом особенностей вашего бизнеса, отрасли и конкретных задач."
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq-section bg-gradient-to-b from-white to-blue-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">
          <span className="bg-gradient-to-r from-blue-600 to-teal-400 text-transparent bg-clip-text">
            Часто задаваемые вопросы
          </span>
        </h2>
        <div className="max-w-3xl mx-auto">
          {faqItems.map((item, index) => (
            <div key={index} className="mb-6">
              <button
                className="flex justify-between items-center w-full p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 border-l-4 border-blue-500"
                onClick={() => toggleAccordion(index)}
              >
                <span className="text-lg font-semibold text-left text-gray-800">{item.question}</span>
                <svg
                  className={`w-6 h-6 text-blue-500 transition-transform duration-300 ${
                    activeIndex === index ? 'transform rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="bg-white px-6 pt-0 overflow-hidden rounded-b-lg shadow-lg"
                  >
                    <motion.p 
                      className="py-6 text-gray-600"
                      initial={{ y: -20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                    >
                      {item.answer}
                    </motion.p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function Landing() {
  const [menuActive, setMenuActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const animatedSectionsRef = useRef([]);

  

  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    animatedSectionsRef.current.forEach((el) => observer.observe(el));

    return () => {
      animatedSectionsRef.current.forEach((el) => observer.unobserve(el));
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !animatedSectionsRef.current.includes(el)) {
      animatedSectionsRef.current.push(el);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    document.body.classList.toggle('overflow-hidden');
  };

  const openModal = () => {
    document.getElementById('contact_modal').showModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const url = 'https://nikta.ai/api/contact/send';
    const message = {
      message: `Имя: ${name}\nТелефон: ${phone}\nСообщение: ${feedbackMessage}`,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Мы получили вашу заявку!');
        setName('');
        setPhone('');
        setFeedbackMessage('');
      } else {
        setError('Ошибка при отправке сообщения. Попробуйте еще раз.');
      }
    } catch (err) {
      console.error(err);
      setError('Произошла ошибка при отправке. Попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="landing-page scroll-smooth overflow-hidden">
      <header className={`header ${isScrolled ? 'shadow-lg transition' : ''}`}>
        <div className="container">
          <div className="header__logo">
            <a className="header__logo-link" href="/">
              <img className="header__logo-image" src={logo} alt="logo" />
            </a>
          </div>
          <nav className="header__nav">
            <a className="header__nav-link" href="#advantages">AI ассистенты</a>
            <a className="header__nav-link" href="#roles">Что они могут</a>
            <a className="header__nav-link" href="#howItWork">Как это работает</a>
            <a className="header__nav-link" href="/cases">Кейсы</a>
          </nav>
          <div className="header__burger">
            <button
              className="lg:hidden text-2xl p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors"
              onClick={toggleMenu}
            >
              ☰
            </button>
          </div>
          <div className="header__login">
            <Link to="/auth" className="header__login-button">Войти</Link>
          </div>
        </div>
      </header>

      <AnimatePresence>
  {menuActive && (
    <motion.div
      initial={{ x: '100%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ type: 'tween', duration: 0.3 }}
      className="fixed inset-0 bg-white z-50 overflow-y-auto flex flex-col"
    >
      <div className="flex-grow p-6 pb-24 relative">
        <button 
          onClick={toggleMenu}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FiX size={24} />
        </button>
        <nav className="mt-24">
          <ul className="space-y-6">
            {[
              { icon: RiRobotLine, text: "AI ассистенты", href: "#advantages" },
              { icon: FiUsers, text: "Что они могут", href: "#roles" },
              { icon: FiMessageSquare, text: "Как это работает", href: "#howItWork" },
              { icon: FiFolder, text: "Кейсы", href: "/cases" },
            ].map((item, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <a
                  href={item.href}
                  className="flex items-center text-xl text-gray-700 hover:text-blue-600 transition-colors"
                  onClick={toggleMenu}
                >
                  <item.icon className="mr-4" size={24} />
                  {item.text}
                </a>
              </motion.li>
            ))}
          </ul>
        </nav>
      </div>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="p-6"
      >
        <Link
          to="/auth"
          className="block w-full py-3 px-6 bg-gradient-to-r from-blue-500 to-teal-400 hover:from-blue-600 hover:to-teal-500 text-white text-center rounded-lg text-lg font-semibold transition-all duration-300 transform hover:scale-105 mb-20"
          onClick={toggleMenu}
        >
          Войти
        </Link>
      </motion.div>
      {/* Градиент снизу */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-blue-100 to-transparent pointer-events-none"></div>
    </motion.div>
  )}
</AnimatePresence>

      <main className="main">
        <section className="first-screen relative overflow-hidden bg-white min-h-screen ">
          <div className="container mx-auto px-4  relative z-10 mb-4 md:mb-48">
            <div className="flex flex-col lg:flex-row items-center justify-between">
              <div className="first-screen__content animate-on-scroll fade-in w-full lg:w-1/2 mb-12 lg:mb-0" ref={addToRefs}>
                <h1 className="first-screen__title text-4xl lg:text-5xl xl:text-6xl font-bold text-gray-900 leading-tight mb-6">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400"> Цифровые сотрудники </span> для вашего бизнеса
                </h1>
                <p className="first-screen__description text-lg lg:text-xl text-gray-700 mb-8">
                  Трансформируем процессы с помощью AI решений
                </p>
                <div className="first-screen__connect flex flex-col sm:flex-row gap-4 items-start justify-start w-full">
                  <button 
                    className="first-screen__connect-button button contact w-full sm:w-auto bg-teal-400 hover:bg-teal-500 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 "
                    onClick={openModal}
                  >
                    Узнать больше
                  </button>
                  <a 
                    href="https://t.me/fron1runner" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="first-screen__connect-button button button_light flex items-center justify-center w-full sm:w-auto bg-blue-100 hover:bg-blue-200 text-neutral font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 "
                  >
                    <FaTelegram className="mr-2 text-2xl " />
                    Написать в Telegram
                  </a>
                </div>
              </div>
              
              <div className="first-screen__figure w-full lg:w-1/2 mt-12 lg:mt-0 flex justify-center items-center" ref={addToRefs}>
                <div className="relative w-full max-w-md lg:max-w-full">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-300 to-teal-200 opacity-50 animate-pulse rounded-full blur-3xl"></div>
                  <img 
                    src={headsImage} 
                    alt="AI Heads" 
                    className="w-full h-auto object-contain relative z-10 ml-4"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-blue-50 to-transparent"></div>



  
        </section>


        

  
        <section className="bg-teal-100 py-20 lg:py-32 overflow-hidden" id="assistants">
  <div className="container mx-auto px-4">
    <div className="max-w-4xl mx-auto">
      {/* Заголовок с анимацией */}
      <div className="space-y-6 text-center mb-16">
        
        
        <h2 className="text-4xl lg:text-6xl font-bold text-gray-900 leading-tight">
          Автоматизируем{' '}
          <span className="relative inline-block min-w-[280px] lg:min-w-[400px]">
            <TypeAnimation
              sequence={[
                'продажи',
                2000,
                'маркетинг',
                2000,
                'поддержку',
                2000,
                'процессы',
                2000,
                'что угодно',
                2000,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
              className="bg-gradient-to-r from-teal-600 to-blue-600 text-transparent bg-clip-text"
            />
            <span className="absolute -bottom-2 left-0 right-0 h-3 bg-teal-200/50 -skew-x-6"></span>
          </span>
        </h2>
      </div>

      {/* Текстовые блоки */}
      <div className="grid md:grid-cols-2 gap-8 md:gap-16">
        <div className="animate-on-scroll fade-in" ref={addToRefs}>
          <p className="text-xl text-gray-700">
            Мы разрабатываем различные решения для автоматизации любых процессов
          </p>
        </div>

        <div className="animate-on-scroll fade-in" ref={addToRefs}>
          <p className="text-xl text-gray-700">
            В особенности мы используем для этого AI инструменты, интегрируя их в ваш кейс
          </p>
        </div>
      </div>
    </div>
  </div>


</section>

<section className="advantages section " id="advantages">
  <div className="container">
    <h2 className="advantages__title h2 animate-on-scroll fade-in" ref={addToRefs}>Почему цифровой сотрудник?</h2>
    <ul className="advantages__list">
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-left" ref={addToRefs}>
        <span>Мгновенные ответы 24/7</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-left" ref={addToRefs}>
        <span>Знает ваш продукт</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-left" ref={addToRefs}>
        <span>Отрабатывает возражения</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-left" ref={addToRefs}>
        <span>Работает с любой информацией</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-right" ref={addToRefs}>
        <span>Экономит на персонале</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-right" ref={addToRefs}>
        <span>Не нужны льготы, отпуска, больничные</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-right" ref={addToRefs}>
        <span>Легкая интеграция с каналами связи</span>
      </li>
      <li className="advantages__list-item t-26 animate-on-scroll slide-in-right" ref={addToRefs}>
        <span>Постоянное улучшение на основе данных</span>
      </li>
    </ul>
  </div>
</section>

        <section className="new-level section">
          <div className="container">
            <div className="new-level__content animate-on-scroll fade-in" ref={addToRefs}>
              <h2 className="new-level__title h2">
                Выведите свой бизнес на новый уровень
              </h2>
              <p className="new-level__description t-26">
                Мы поможем вам достичь новых высот, используя современные технологии и инновационные решения
              </p>
              <div className="new-level__connect">
                <button className="new-level__connect-button button button_light contact" onClick={openModal}>
                  Начать трансформацию
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="roles section " id="roles">
          <div className="container">
            <h2 className="roles__title h2 animate-on-scroll fade-in" ref={addToRefs}>AI асситенты умеют не только общаться</h2>

            <div className="roles__cards ">
              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role1Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Эффективный продажник</h3>
                <p className="roles__card-text t-20">
                  Знает ваш продукт, клиентов, прекрасно подстраивается под любую ситуацию.
                </p>
              </div>

              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role2Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Таск-менеджер</h3>
                <p className="roles__card-text t-20">
                  Гибко управляет задачами исходя из потребностей и особенностей вашего бизнеса.
                </p>
              </div>

              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role3Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Бизнес-советник</h3>
                <p className="roles__card-text t-20">
                  Обладает исчерпывающими знаниями, логикой, способен объяснить сложное даже ребенку.
                </p>
              </div>

              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role4Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Безупречный исполнитель</h3>
                <p className="roles__card-text t-20">
                  Выполняет сложные задания, с недоступной для специалистов-людей точностью и скоростью.
                </p>
              </div>

              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role5Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Учитель и старший товарищ</h3>
                <p className="roles__card-text t-20">
                  Знает, как надо работать в вашей команде, опирающийся на ваши правила и принципы.
                </p>
              </div>

              <div className="roles__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="roles__card-icon">
                  <img className="roles__card-icon-image" src={role6Image} alt="role" />
                </figure>
                <h3 className="roles__card-title t-26">Контент-менеджер</h3>
                <p className="roles__card-text t-20">
                  Создающий оригинальный контент, новостные посты, обрабатывающий различные документы.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-us section bg-teal-100">
        <div className="container">
          <div className="contact-us__content animate-on-scroll fade-in" ref={addToRefs}>
            <h2 className="contact-us__title h2">Нужно индивидуальное решение? Расскажите нам</h2>
            <p className="contact-us__description t-26">
              Мы разработаем умного ассистента под ваши уникальные бизнес-потребности для мгновенного повышения эффективности.
            </p>
            <div className="contact-us__connect">
              <button className="contact-us__connect-button button contact" onClick={openModal}>Обсудить решение</button>
            </div>
          </div>
        </div>
      </section>

        <section className="hierarchy section">
          <div className="container">
            <h2 className="hierarchy__title h2 animate-on-scroll fade-in" ref={addToRefs}>AI ассистенты быстро учатся</h2>

            <p className="hierarchy__description t-26 animate-on-scroll fade-in" ref={addToRefs}>
              Их можно обучить любому сценарию
            </p>

            <div className="hierarchy__cards">
              <div className="hierarchy__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="hierarchy__card-icon">
                  <img
                    className="hierarchy__card-icon-image"
                    src={hierarchy1Image}
                    alt="hierarchy"
                  />
                </figure>
                <h3 className="hierarchy__card-title t-26">Анализируют данные</h3>
              <p className="hierarchy__card-text t-20">
                Умные ассистенты смотрят на ваши данные по документам и принимают решения
              </p>
            </div>

              <div className="hierarchy__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="hierarchy__card-icon">
                  <img
                    className="hierarchy__card-icon-image"
                    src={hierarchy2Image}
                    alt="hierarchy"
                  />
                </figure>
                <h3 className="hierarchy__card-title t-26">Взаимодействуют с людьми</h3>
                <p className="hierarchy__card-text t-20">
                  И не только с клиентами, но и с вами, например для уточнения деталей
                </p>
              </div>

              <div className="hierarchy__card animate-on-scroll fade-in" ref={addToRefs}>
                <figure className="hierarchy__card-icon">
                  <img
                    className="hierarchy__card-icon-image"
                    src={hierarchy3Image}
                    alt="hierarchy"
                  />
                </figure>
                <h3 className="hierarchy__card-title t-26">Экономят время</h3>
                <p className="hierarchy__card-text t-20">
                  Пока вы занимаетесь другими задачами, они учатся и работают
                </p>
              </div>
            </div>
          </div>
        </section>

        
        <section className="features-showcase py-32 bg-gradient-to-b from-white via-blue-50/50 to-white relative overflow-hidden">
  {/* Декоративные элементы */}
  <div className="absolute inset-0">
    <div className="absolute top-1/4 left-0 w-72 h-72 bg-blue-200/20 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
    <div className="absolute top-1/3 right-0 w-72 h-72 bg-teal-200/20 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
    <div className="absolute bottom-1/4 left-1/4 w-72 h-72 bg-pink-200/20 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
  </div>

  <div className="container mx-auto px-4 relative z-10">
    <div className="text-center mb-24">
      <h2 className="text-5xl font-extrabold mb-6">
        <span className="bg-gradient-to-r from-blue-600 to-teal-400 text-transparent bg-clip-text">
          Возможности платформы
        </span>
      </h2>
      <p className="text-xl text-gray-600 max-w-2xl mx-auto">
        Полный набор инструментов для автоматизации вашего бизнеса с помощью искусственного интеллекта
      </p>
    </div>

    {/* AI Чат */}
    <div className="mb-40 relative">
      <div className="flex flex-col lg:flex-row items-center gap-16">
        <div className="lg:w-1/2 order-2 lg:order-1 space-y-8">
          <div className="inline-flex items-center px-4 py-2 bg-blue-50 rounded-full text-blue-600 font-semibold text-sm">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
            </svg>
            AI Чат
          </div>
          <h3 className="text-4xl font-bold text-gray-900">Умный AI-ассистент</h3>
          <p className="text-xl text-gray-600">
            Автоматизируйте общение с клиентами 24/7 с помощью искусственного интеллекта
          </p>
          <div className="grid gap-6">
            {[
              {
                title: "Контекстное общение",
                desc: "AI понимает контекст разговора и поддерживает естественный диалог",
                icon: "M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              },
              {
                title: "Умная маршрутизация",
                desc: "Автоматическая передача сложных запросов операторам",
                icon: "M13 5l7 7-7 7M5 5l7 7-7 7"
              },
              {
                title: "Аналитика общения",
                desc: "Подробная статистика и анализ всех диалогов",
                icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              }
            ].map((item, index) => (
              <div key={index} className="flex items-start space-x-4 bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                    </svg>
                  </div>
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-1">{item.title}</h4>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-1/2 order-1 lg:order-2">
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-blue-500 to-teal-500 rounded-2xl opacity-30 blur-lg"></div>
            <img 
              src={chat1} 
              alt="AI Chat Interface" 
              className="relative rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-500 transform hover:scale-[1.02]" 
            />
          </div>
        </div>
      </div>
    </div>

    {/* AI Рассылки */}
    <div className="mb-40 relative">
      <div className="flex flex-col lg:flex-row items-center gap-16">
        <div className="lg:w-1/2 order-1">
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-2xl opacity-30 blur-lg"></div>
            <img 
              src={mails1} 
              alt="AI Mailing System" 
              className="relative rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-500 transform hover:scale-[1.02]" 
            />
          </div>
        </div>
        <div className="lg:w-1/2 order-2 space-y-8">
          <div className="inline-flex items-center px-4 py-2 bg-purple-50 rounded-full text-purple-600 font-semibold text-sm">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            Рассылки
          </div>
          <h3 className="text-4xl font-bold text-gray-900">Умные AI-рассылки</h3>
          <p className="text-xl text-gray-600">
            Автоматизируйте общение в популярных мессенджерах с помощью AI
          </p>
          <div className="grid gap-6">
            {[
              {
                title: "Telegram",
                desc: "Персонализированные рассылки с высокой конверсией",
                icon: "M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              },
              {
                title: "WhatsApp",
                desc: "Умные рассылки с учетом активности клиента",
                icon: "M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
              },
              {
                title: "Аналитика",
                desc: "Детальная статистика по каждой рассылке",
                icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              }
            ].map((item, index) => (
              <div key={index} className="flex items-start space-x-4 bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-purple-50 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                    </svg>
                  </div>
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-1">{item.title}</h4>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* Умные формы */}
    <div className="relative">
      <div className="flex flex-col lg:flex-row items-center gap-16">
        <div className="lg:w-1/2 order-2 lg:order-1 space-y-8">
          <div className="inline-flex items-center px-4 py-2 bg-pink-50 rounded-full text-pink-600 font-semibold text-sm">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
            Формы
          </div>
          <h3 className="text-4xl font-bold text-gray-900">AI-формы</h3>
          <p className="text-xl text-gray-600">
            Умные формы с автоматическим отслеживанием заполнения
          </p>
          <div className="grid gap-6">
            {[
              {
                title: "Умные напоминания",
                desc: "AI-бот напомнит о незаполненных полях",
                icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              },
              {
                title: "Прогресс заполнения",
                desc: "Автоматическое отслеживание прогресса",
                icon: "M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
              },
              {
                title: "Умные подсказки",
                desc: "AI помогает правильно заполнить форму",
                icon: "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              }
            ].map((item, index) => (
              <div key={index} className="flex items-start space-x-4 bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-pink-50 rounded-xl flex items-center justify-center">
                    <svg className="w-6 h-6 text-pink-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={item.icon} />
                    </svg>
                  </div>
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-1">{item.title}</h4>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-1/2 order-1 lg:order-2">
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-pink-500 to-purple-500 rounded-2xl opacity-30 blur-lg"></div>
            <img 
              src={forms1} 
              alt="Smart Forms" 
              className="relative rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-500 transform hover:scale-[1.02]" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <style jsx>{`
    @keyframes blob {
      0% {
        transform: translate(0px, 0px) scale(1);
      }
      33% {
        transform: translate(30px, -50px) scale(1.1);
      }
      66% {
        transform: translate(-20px, 20px) scale(0.9);
      }
      100% {
        transform: translate(0px, 0px) scale(1);
      }
    }
    .animate-blob {
      animation: blob 7s infinite;
    }
    .animation-delay-2000 {
      animation-delay: 2s;
    }
    .animation-delay-4000 {
      animation-delay: 4s;
    }
  `}</style>
</section>

<section className="benefits py-24 bg-gradient-to-b from-white via-blue-50/30 to-white">
  <div className="container mx-auto px-4">
    <div className="text-center mb-16">
      <h2 className="text-5xl font-extrabold mb-6">
        <span className="bg-gradient-to-r from-blue-600 to-teal-400 text-transparent bg-clip-text">
          Почему выбирают нас
        </span>
      </h2>
      <p className="text-xl text-gray-600 max-w-2xl mx-auto">
        AI-ассистент станет надежным помощником в развитии вашего бизнеса
      </p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[
        {
          title: "Экономия времени",
          description: "AI-ассистент работает 24/7, мгновенно отвечает на вопросы и выполняет рутинные задачи",
          icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z",
          color: "blue"
        },
        {
          title: "Снижение затрат",
          description: "Сокращение расходов на поддержку клиентов и автоматизация процессов продаж",
          icon: "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
          color: "green"
        },
        {
          title: "Рост продаж",
          description: "Увеличение конверсии благодаря мгновенным ответам и персонализированному подходу",
          icon: "M13 7h8m0 0v8m0-8l-8 8-4-4-6 6",
          color: "teal"
        },
        {
          title: "Масштабируемость",
          description: "AI легко справляется с растущим потоком клиентов без потери качества обслуживания",
          icon: "M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
          color: "purple"
        },
        {
          title: "Аналитика и инсайты",
          description: "Получайте ценные данные о клиентах и их потребностях на основе анализа диалогов",
          icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
          color: "pink"
        },
        {
          title: "Постоянное обучение",
          description: "AI-ассистент постоянно учится и совершенствуется на основе новых диалогов",
          icon: "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253",
          color: "indigo"
        }
      ].map((benefit, index) => (
        <div 
          key={index} 
          className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-[1.02]"
        >
          <div className={`w-16 h-16 bg-${benefit.color}-50 rounded-xl flex items-center justify-center mb-6`}>
            <svg 
              className={`w-8 h-8 text-${benefit.color}-500`} 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={benefit.icon} />
            </svg>
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-4">{benefit.title}</h3>
          <p className="text-gray-600 leading-relaxed">{benefit.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>

        <section className="product-features bg-gradient-to-r from-blue-50 to-teal-50 py-24 relative overflow-hidden">
  <div className="container mx-auto px-4 relative z-10">
    <h2 className="text-5xl font-extrabold text-center mb-16 text-gray-800 leading-tight animate-fade-in-up">
      Наши <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-teal-400">AI решения</span>
    </h2>
    
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start relative">
      {/* Фоновая анимация */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-teal-300 opacity-30 animate-pulse rounded-full blur-3xl"></div>
      
      {/* Готовый продукт */}
      <div className="bg-white rounded-3xl shadow-2xl p-8 border-2 border-blue-200 transform hover:scale-105 transition-all duration-300 relative z-10">
        <div className="absolute top-0 right-0 -mt-4 -mr-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white text-sm font-bold px-4 py-2 rounded-full transform rotate-12">Готовое решение</div>
        <h3 className="text-3xl font-bold mb-8 text-blue-600">AI-ассистент "под ключ"</h3>
        <div className="mb-8 flex items-baseline">
    <span className="text-4xl font-bold text-gray-900">от 30 000</span>
    <span className="text-xl text-gray-600 ml-2">₽/мес</span>
  </div>
  <p className="text-gray-700 mb-8 text-lg">Асситент общается и продает в tg и whatsapp:</p>
        <div className="space-y-6">
          {[
            { title: "Умное общение с клиентами", desc: "AI-ассистент ведет диалоги 24/7", icon: "M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" },
            { title: "Интеллектуальные AI-рассылки", desc: "Автоматизированные кампании", icon: "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" },
            { title: "Управление сценариями продаж", desc: "Настройка под разные каналы", icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" },
            { 
              title: "Аналитика и отчетность", 
              desc: "Статистика и инсайты по работе AI", 
              icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            }
           
          ].map((item, index) => (
            <div key={index} className="feature-item flex items-start space-x-4 animate-fade-in-up" style={{animationDelay: `${index * 0.2}s`}}>
              <div className="flex-shrink-0 w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon}></path>
                </svg>
              </div>
              <div>
                <h4 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h4>
                <p className="text-gray-600">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10">
        <button onClick={openModal} className="inline-block bg-gradient-to-r from-teal-500 to-blue-400 text-white font-bold py-4 px-8 rounded-full hover:from-teal-600 hover:to-blue-500 transition duration-300 transform hover:scale-105 hover:shadow-lg text-lg">
            Хочу его!
          </button>
        </div>
      </div>
      
      {/* Разработка на заказ */}
      <div className="bg-white rounded-3xl shadow-2xl p-8 border-2 border-teal-200 transform hover:scale-105 transition-all duration-300 relative z-10">
        <div className="absolute top-0 right-0 -mt-4 -mr-4 bg-gradient-to-r from-teal-500 to-teal-600 text-white text-sm font-bold px-4 py-2 rounded-full transform rotate-12">Индивидуально</div>
        <h3 className="text-3xl font-bold mb-8 text-teal-600">AI-решения на заказ</h3>
        <div className="mb-8 flex items-center">
    
    <span className="text-4xl font-bold text-gray-900">Цена договорная</span>
   
    </div>

        <p className="text-gray-700 mb-8 text-lg">Создаем индивидуальные AI-решения под ваш бизнес:</p>
        <ul className="space-y-6 mb-10">
          {[
            { text: "Анализ ваших бизнес-процессов", icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" },
            { text: "Разработка уникальных AI-алгоритмов", icon: "M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" },
            { text: "Интеграция с вашими системами", icon: "M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" },
            { text: "Обучение и поддержка", icon: "M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" }
          ].map((item, index) => (
            <li key={index} className="flex items-center space-x-4 animate-fade-in-up" style={{animationDelay: `${index * 0.2}s`}}>
              <div className="flex-shrink-0 w-12 h-12 bg-teal-100 rounded-full flex items-center justify-center">
                <svg className="w-6 h-6 text-teal-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon}></path>
                </svg>
              </div>
              <span className="text-gray-700 text-lg">{item.text}</span>
            </li>
          ))}
        </ul>
        <div className="mt-10">
          <button onClick={openModal} className="inline-block bg-gradient-to-r from-teal-500 to-blue-400 text-white font-bold py-4 px-8 rounded-full hover:from-teal-600 hover:to-blue-500 transition duration-300 transform hover:scale-105 hover:shadow-lg text-lg">
            Обсудить проект
          </button>
        </div>
      </div>
    </div>
  </div>
  
  {/* Декоративные элементы */}
  <div className="absolute top-0 left-0 w-64 h-64 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
  <div className="absolute top-0 right-0 w-64 h-64 bg-teal-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
  <div className="absolute -bottom-32 left-1/2 w-64 h-64 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
</section>

<style jsx>{`
  @keyframes blob {
    0% {
      transform: translate(0px, 0px) scale(1);
    }
    33% {
      transform: translate(30px, -50px) scale(1.1);
    }
    66% {
      transform: translate(-20px, 20px) scale(0.9);
    }
    100% {
      transform: translate(0px, 0px) scale(1);
    }
  }
  .animate-blob {
    animation: blob 7s infinite;
  }
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
`}</style>

        <section className="how-it-work section" id="howItWork">
  <div className="container">
    <h2 className="how-it-work__title h2 animate-on-scroll fade-in" ref={addToRefs}>Как это работает</h2>
    <p className="how-it-work__description t-26 animate-on-scroll fade-in" ref={addToRefs}>Всё предельно просто</p>

    <div className="how-it-work__cards">
      <div className="how-it-work__card animate-on-scroll fade-in" ref={addToRefs}>
        <figure className="how-it-work__card-icon">
          <img className="how-it-work__card-icon-image" src={hierarchy1Image1} alt="how-it-work" />
        </figure>
        <p className="how-it-work__card-text t-26">Вы объясняете задачу</p>
      </div>

      <div className="how-it-work__card animate-on-scroll fade-in" ref={addToRefs}>
        <figure className="how-it-work__card-icon">
          <img className="how-it-work__card-icon-image" src={hierarchy2Image1} alt="how-it-work" />
        </figure>
        <p className="how-it-work__card-text t-26">Мы обучаем AI сотрудника</p>
      </div>

      <div className="how-it-work__card animate-on-scroll fade-in" ref={addToRefs}>
        <figure className="how-it-work__card-icon">
          <img className="how-it-work__card-icon-image" src={hierarchy3Image1} alt="how-it-work" />
        </figure>
        <p className="how-it-work__card-text t-26">Сотрудник начинает работать</p>
      </div>
    </div>
  </div>
</section>



<FAQ />
      </main>

      <footer className="footer flex flex-col">
        <div className="container flex flex-col items-start md:items-end w-full">
          <nav className="footer__nav text-right">
            <a className="footer__nav-link t-20" href="#assistants">О сотрудниках</a>
            <a className="footer__nav-link t-20" href="#advantages">Преимущества</a>
            <a className="footer__nav-link t-20" href="#roles">Роли</a>
            <a className="footer__nav-link t-20" href="/nikta/cases">Кейсы</a>
          </nav>

          <div className="footer__content flex justify-between w-full">
            <figure className="footer__logo">
              <img className="footer__logo-image" src={logo} alt="logo" />
            </figure>

            <div className="footer__connect">
              <button className="footer__connect-button button contact" onClick={openModal}>
                Давайте общаться
              </button>
            </div>
          </div>

          <div className="footer__details flex flex-col md:flex-row justify-between w-full mt-4">
            <div className="footer__company-info">
              <span className="text-white">ООО "ЗВЕНО" ИНН 5260491864</span><br />
              <a href="mailto:nikta.ai@yandex.ru" className="text-white">nikta.ai@yandex.ru</a>
            </div>
            <div className="footer__links">
              <a href="/politika.docx" download className="text-white hover:text-blue-500">Политика конфиденциальности</a><br />
              <a href="/pers.docx" download className="text-white hover:text-blue-500">Обработка персональных данных</a>
            </div>
            <span className="text-white">©Nikta 2024</span>
          </div>
        </div>
      </footer>

     
      <dialog id="contact_modal" className="modal">
  <div className="modal-box bg-gradient-to-br from-white to-blue-50 rounded-3xl shadow-2xl text-center flex flex-col items-center justify-center gap-8 p-10 max-w-lg mx-auto border border-blue-100 relative">
    {/* Добавляем крестик для закрытия */}
    <button 
      onClick={() => document.getElementById('contact_modal').close()}
      className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
 
    <h3 className="font-bold text-3xl mb-2 text-gray-800 leading-tight">
      Мы свяжемся с вами <span className="text-blue-600">в течение 15 минут</span>
    </h3>
    <form className="w-full space-y-6" onSubmit={handleSubmit}>
      {error && <div className="text-red-500 font-semibold bg-red-50 p-3 rounded-lg">{error}</div>}
      {success && <div className="text-green-500 font-semibold bg-green-50 p-3 rounded-lg">{success}</div>}
      <div className="relative">
        <input
          type="tel"
          placeholder="Ваш номер телефона"
          className="input w-full bg-white border-2 border-blue-100 text-lg p-4 rounded-xl focus:border-blue-400 focus:ring-2 focus:ring-blue-200 transition-all duration-300 pl-12"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-400 absolute left-4 top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
        </svg>
      </div>
      <div className="flex flex-col items-center w-full gap-4 pt-4">
  <button
    className="btn w-full h-14 bg-gradient-to-r from-blue-500 to-teal-400 hover:from-blue-600 hover:to-teal-500 border-0 text-white text-lg font-bold rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg"
    type="submit"
    disabled={loading}
  >
    {loading ? 'Отправка...' : 'Отправить'}
  </button>
  
  <a 
    href="https://t.me/fron1runner" 
    target="_blank" 
    rel="noopener noreferrer"
    className="btn w-full h-14 bg-white border-2 border-blue-500 text-blue-500 hover:bg-blue-50 text-lg font-bold rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg flex items-center justify-center gap-2"
  >
    <FaTelegram className="text-xl" />
    Написать в Telegram
  </a>
</div>
    </form>
  </div>
  <form method="dialog" className="modal-backdrop">
    <button>close</button>
  </form>
</dialog>

    </div>
  );
}

export default Landing;
                
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  FaStop,
  FaPause,
  FaPlay,
  FaDownload,
  FaArrowDown,
  FaArrowUp,
  FaSmile,
  FaTimes,
  FaInfoCircle
} from 'react-icons/fa';
import { ImSpinner2 } from "react-icons/im";
import { FiSearch, FiChevronLeft, FiMoreVertical } from "react-icons/fi";
import EmojiPicker from 'emoji-picker-react';
import { motion, AnimatePresence } from 'framer-motion';
import api from './api';
import styleScroll from './styleScroll.css';

const ChatWindow = ({
  messages: initialMessages,
  onSendMessage,
  onBack,
  clientName,
  clientFirstName,
  clientLastName,
  selectedChannel,
  selectedDialog,
  scenarioStatus,
  onScenarioToggle
}) => {
  const [messages, setMessages] = useState(initialMessages);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [localScenarioStatus, setLocalScenarioStatus] = useState(scenarioStatus);
  const [isTogglingScenario, setIsTogglingScenario] = useState(false);
  const messagesContainerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const textareaRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const emojiPickerRef = useRef(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioRefs = useRef({});

  const messageVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  const displayName = clientFirstName && clientLastName
    ? `${clientFirstName} ${clientLastName}`
    : clientName || 'Выберите клиента';

  const renderName = (currentMessage) => {
    if (selectedDialog?.type === 'private') {
      return null;
    }

    return (
        <div className="text-sm font-semibold text-base-content">
          {currentMessage.first_name} {currentMessage.last_name}
        </div>
    );
  };

  const formatDateModal = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA');
  };

  // Функция для получения данных о клиенте
  const fetchClientData = async (clientId) => {
    try {
      const response = await api.getClient(clientId);
      setClientData(response.data);
    } catch (err) {
      console.log('Не удалось загрузить данные клиента');
      setClientData(null);
    }
  };

  const GroupChatDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [needBotAnswer, setNeedBotAnswer] = useState(selectedDialog?.need_bot_answer || false);
    // Открытие модального окна
    const openModal = async () => {
      if (selectedDialog?.type === 'private' && selectedDialog.client_id) {
        await fetchClientData(selectedDialog.client_id);
      }

      setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);

    // Обработка изменения состояния чекбокса
    const handleCheckboxChange = (member) => {
      setSelectedMembers((prev) => {
        if (prev.includes(member.id)) {
          return prev.filter((item) => item !== member.id);
        } else {
          return [...prev, member.id];
        }
      });
    };

    const UserInfo = ({clientData}) => {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      };

      const userInfoFields = [
        { label: "Логин", value: clientData?.login },
        { label: "Телефон", value: clientData?.phone },
        { label: "Организация", value: clientData?.cl_name },
        { label: "Тариф", value: clientData?.tariff_name },
        { label: "Статус оплаты", value: clientData?.payment_status },
        { label: "Тип оплаты", value: clientData?.tax_system },
        { label: "Дата окончания тарифа", value: clientData?.tariff_end_date ? formatDate(clientData.tariff_end_date) : null },
      ];

      return (
          <div className="flex items-center mb-4">
            <div className="avatar">
              <div className="w-20 h-20 rounded-full flex items-center justify-center bg-base-300 dark:bg-base-600">
                {selectedUser?.client_avatar ? (
                    <img src={clientData.client_avatar} alt="avatar" className="rounded-full" />
                ) : (
                    <div className="w-20 h-20 rounded-full bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 flex items-center justify-center">
              <span className="text-white text-lg font-semibold">
                {clientData?.login?.charAt(0).toUpperCase()}
              </span>
                    </div>
                )}
              </div>
            </div>
            <div className="ml-4">
              <div className="font-semibold text-xl text-base-content mb-1">
                {clientData?.first_name || clientData?.last_name ? (
                    <>
                      <span>{clientData.first_name || ''} {clientData.last_name || ''}</span>
                    </>
                ) : null}
              </div>
              {userInfoFields.map(
                  ({ label, value }) => value && (
                      <div key={label} className="text-sm text-base-content opacity-70">
                        {label}: {value}
                      </div>
                  )
              )}
            </div>
          </div>
      );
    };


    const handleBotAnswerChange = () => {
      setNeedBotAnswer((prev) => !prev);}

    const handleSave = () => {
      closeModal();
    };


    const renderModal = () => {
      if (!isModalOpen) return null;

      return (
          <div className="flex flex-col h-full bg-base-100 dark:bg-base-900">
            <div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                onClick={closeModal}
            >
              <motion.div
                  className="bg-base-100 p-7 rounded-lg max-w-xl max-h-[70vh] overflow-y-auto flex flex-col"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.3 }}
                  onClick={(e) => e.stopPropagation()}
              >
                {/* Если тип чата 'private', показываем информацию о пользователе */}
                {selectedDialog?.type === 'private' && (
                   <UserInfo clientData={clientData} />
                )}

                {/* Если это группа, отображаем участников */}
                {selectedDialog?.type === 'chat' && (
                    <>
                      <div className="flex items-center mb-4 justify-between">
                        <h3 className="text-lg font-semibold mr-3">Участники группы</h3>
                        <h3 className="text-lg font-semibold text-end ml-3">Нужен ответ бота</h3>
                      </div>

                      <div className="mb-4">
                        {selectedDialog.members.map((member) => (
                            <div key={member.id} className="flex items-center text-sm justify-between mb-2">
                              <div>{`${member.first_name} ${member.last_name}`}</div>
                              <input
                                  type="checkbox"
                                  checked={selectedMembers.includes(member)}
                                  onChange={() => handleCheckboxChange(member)}
                                  className="checkbox checkbox-primary"
                              />
                            </div>
                        ))}
                      </div>
                      {/* Кнопка для сохранения выбранных участников */}
                      <div className="mt-4 flex justify-center items-center">
                        <button
                            onClick={handleSave}
                            className="btn btn-primary px-4 py-2 rounded-md"
                        >
                          Сохранить
                        </button>
                      </div>
                    </>
                )}

              </motion.div>
            </div>
          </div>
      );
    };

    return (
        <div>
          <button
              className="font-semibold text-base-content"
              onClick={openModal}
          >
            {displayName}
          </button>
          {renderModal()}
        </div>
    );
  };


  const handleShowUserModal = async (user) => {
    setSelectedUser(user);
    setIsLoading(true);
    const clientId = user.client_id;
    await fetchClientData(clientId);
    setIsLoading(false);
    setShowUserModal(true);
  };

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
    setClientData(null);
  };

  useEffect(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  useEffect(() => {
    setLocalScenarioStatus(scenarioStatus);
  }, [scenarioStatus]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
    }
  }, [selectedChannel, selectedDialog]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        setIsAtBottom(scrollHeight - scrollTop - clientHeight < 1);
      };
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      scrollToBottom();
    }, 800);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleAudio = (fileId) => {
    const audioElement = audioRefs.current[fileId];
    if (!audioElement) return;

    if (playingAudio === fileId) {
      audioElement.pause();
      setPlayingAudio(null);
    } else {
      // Остановить предыдущее воспроизведение
      if (playingAudio && audioRefs.current[playingAudio]) {
        audioRefs.current[playingAudio].pause();
      }
      audioElement.play();
      setPlayingAudio(fileId);
    }
  };

  const handleSendMessage = useCallback(async () => {
    if ((message.trim() === '' && !selectedFile) || !selectedChannel || !selectedDialog) return;

    setIsSending(true);

    try {
      const newMessage = await onSendMessage(message, selectedFile);
      if (newMessage) {
        setMessages(prevMessages => [...prevMessages, newMessage]);
      }
      setMessage('');
      setSelectedFile(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = '48px';
      }
      scrollToBottom();
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
    } finally {
      setIsSending(false);
    }
  }, [message, selectedFile, selectedChannel, selectedDialog, onSendMessage]);

  const handleScenarioToggle = useCallback(async () => {
    if (!selectedChannel || !selectedDialog) return;

    setIsTogglingScenario(true);

    try {
      await onScenarioToggle(!localScenarioStatus);
      setLocalScenarioStatus(!localScenarioStatus);
    } catch (error) {
      console.error('Ошибка при обновлении статуса сценария:', error);
    } finally {
      setIsTogglingScenario(false);
    }
  }, [selectedChannel, selectedDialog, localScenarioStatus, onScenarioToggle]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    const isUTC = timestamp.endsWith('Z') || /[+-]\d{2}:\d{2}$/.test(timestamp);
    const isoString = isUTC ? timestamp : `${timestamp}Z`;
    const date = new Date(isoString);

    if (isNaN(date)) return '';

    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
    }
  };


  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = '48px';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 96)}px`;
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
  };

  const onEmojiClick = (emojiObject) => {
    setMessage(prevMessage => prevMessage + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  // Добавьте функцию форматирования времени
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const [audioDurations, setAudioDurations] = useState({});

  const renderFileContent = (file) => {
    if (!file) return null;

    const fileType = file.mime_type || '';
    const fileUrl = `https://nikta.ai/storage/${file.id}/${file.file_name}`;
    const fileName = file.file_name || 'Файл';

    // Специальное оформление для голосовых сообщений в стиле Telegram
    if (fileType === 'audio/ogg' || fileType === 'audio/mpeg') {
      return (
          <div className="mt-1 flex items-center gap-2">
            {/* Кнопка Play */}
            <button
                className="w-10 h-10 rounded-full bg-primary flex items-center justify-center ml-2"
                onClick={() => toggleAudio(file.id)}
            >
              {playingAudio === file.id ? (
                  <FaPause className="text-white text-sm " />
              ) : (
                  <FaPlay className="text-white text-sm " />
              )}
            </button>

            {/* Волна и время */}
            <div className="flex-grow bg-primary/10 rounded-2xl px-3 py-2">
              <div className="flex items-center h-4 gap-[2px]">
                {[...Array(27)].map((_, i) => (
                    <div
                        key={i}
                        className={`w-[3px] bg-primary transition-all duration-300 ${
                            playingAudio === file.id ? 'animate-wave' : ''
                        }`}
                        style={{
                          height: `${Math.sin(i * 0.5) * 8 + 8}px`,
                          animationDelay: `${i * 0.05}s`,
                        }}
                    />
                ))}
              </div>
              <div className="text-xs text-base-content/50 mt-1">
                {formatTime(audioDurations[file.id] || 0)}
              </div>
            </div>

            {/* Скрытый аудио-элемент */}
            <audio
                ref={(el) => (audioRefs.current[file.id] = el)}
                className="hidden"
                onEnded={() => setPlayingAudio(null)}
                onLoadedMetadata={(e) => {
                  setAudioDurations((prev) => ({
                    ...prev,
                    [file.id]: e.target.duration,
                  }));
                }}
            >
              <source src={fileUrl} type={fileType} />
            </audio>
          </div>
      );
    }

    // Для изображений
    if (fileType.startsWith('image/')) {
      return (
          <img
              src={fileUrl}
              alt={fileName}
              className="max-w-80 cursor-pointer rounded"
              onClick={() => setSelectedImage({ url: fileUrl, name: fileName })}
          />
      );
    }

    const fileExtension = fileName.split('.').pop().toUpperCase();
    return (
        <div className="flex items-center bg-base-200 mt-0 p-2 w-full max-w-[300px]">
          <div className="w-10 h-10 bg-primary/10 text-primary rounded flex items-center justify-center mr-3 flex-shrink-0">
            <span className="text-xs font-medium">{fileExtension.slice(0, 3)}</span>
          </div>
          <div className="flex-grow min-w-0">
            <div className="text-sm text-base-content truncate">{fileName}</div>
            <div className="text-xs text-base-content/70">{formatFileSize(file.size)}</div>
          </div>
          <a
              href={fileUrl}
              target="_blank"
              download={fileName}
              className="w-10 h-10 bg-primary/10 text-primary rounded flex items-center justify-center ml-2 flex-shrink-0"
          >
            <FaDownload />
          </a>
        </div>
    );
  };


  // Вспомогательная функция для форматирования размера файла
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach(msg => {
      const date = new Date(msg.created_at).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
    });
    return groups;
  };

  const formatDateHeader = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Сегодня';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Вчера';
    } else {
      return date.toLocaleDateString();
    }
  };

  const filteredMessages = React.useMemo(() => {
    return messages.filter(msg =>
      msg && (
        (msg.message && typeof msg.message === 'string' && msg.message.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (msg.file && msg.file.file_name && msg.file.file_name.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    );
  }, [messages, searchQuery]);

  const groupedMessages = groupMessagesByDate(filteredMessages);

  const ImagePopup = ({ image, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
      <div className="bg-base-100 p-4 rounded-lg max-w-3xl max-h-[70vh] flex flex-col" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-end mb-2">
          <button onClick={onClose} className="text-base-content hover:text-primary">
            <FaTimes />
          </button>
        </div>
        <img src={image.url} alt={image.name} className="max-w-full max-h-[40vh] object-contain mb-4" />
        <a
          href={image.url}
          download={image.name}
          className="btn btn-primary w-full"
        >
          <FaDownload className="mr-2" /> Скачать
        </a>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-full bg-base-100 dark:bg-base-900 "  >
      {showUserModal && selectedUser && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
               onClick={handleCloseUserModal}>
            <motion.div
                className="bg-base-100 p-10 rounded-lg max-w-xl max-h-[70vh] overflow-y-auto flex flex-col"
                initial={{opacity: 0, scale: 0.9}}
                animate={{opacity: 1, scale: 1}}
                exit={{opacity: 0, scale: 0.9}}
                transition={{duration: 0.3}}
                onClick={(e) => e.stopPropagation()}  // Чтобы клик по модальному окну не закрывал его
            >
              <div className="flex items-center mb-4">
                <div className="avatar">
                  <div className="w-20 h-20 rounded-full flex items-center justify-center bg-base-300 dark:bg-base-600">
                    {selectedUser.client_avatar ? (
                        <img src={selectedUser.client_avatar} alt="avatar" className="rounded-full"/>
                    ) : (
                        <div
                            className="w-20 h-20 rounded-full bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 flex items-center justify-center">
                      <span className="text-white text-lg font-semibold">
                        {clientData?.login.charAt(0).toUpperCase()}
                      </span>
                        </div>
                    )}
                  </div>
                </div>
                <div className="ml-4">
                  <div className="font-semibold text-xl text-base-content mb-1">
                    {clientData?.first_name || clientData?.last_name ? (
                        <>
                          <span>{clientData.first_name || ''} {clientData.last_name || ''}</span>
                        </>
                    ) : null}
                  </div>
                  {clientData?.login && (
                      <div className="text-sm text-base-content opacity-70">
                        Логин: {clientData.login}
                      </div>
                  )}
                  {clientData?.phone && (
                      <div className="text-sm text-base-content opacity-70">
                        Телефон: {clientData.phone}
                      </div>
                  )}
                  {clientData?.cl_name && (
                      <div className="text-sm text-base-content opacity-70">
                        Организация: {clientData.cl_name}
                      </div>
                  )}
                  {clientData?.tariff_name && (
                      <div className="text-sm text-base-content opacity-70">
                        Тариф: {clientData.tariff_name}
                      </div>
                  )}
                  {clientData?.payment_status && (
                      <div className="text-sm text-base-content opacity-70">
                        Статус оплаты: {clientData.payment_status}
                      </div>
                  )}
                  {clientData?.tax_system && (
                      <div className="text-sm text-base-content opacity-70">
                        Тип оплаты: {clientData.tax_system}
                      </div>
                  )}
                  {clientData?.tariff_end_date && (
                      <div className="text-sm text-base-content opacity-70">
                        Дата окончания тарифа: {formatDate(clientData.tariff_end_date)}
                      </div>
                  )}
                </div>
              </div>
            </motion.div>
          </div>
      )}
      <div className="p-4 flex items-center justify-between text-xs ">
        <div className="flex items-center">
          <button
              className="lg:hidden mr-4 text-base-content hover:text-primary"
              onClick={onBack}
          >
            <FiChevronLeft className="h-6 w-6"/>
          </button>
          <div className="flex items-center">
            <div className="avatar">
              <div className="w-8 h-8 rounded-full flex items-center justify-center bg-base-300 dark:bg-base-600">
                {selectedDialog?.client_avatar ? (
                    <img src={selectedDialog.client_avatar} alt="avatar" className="rounded-full"/>
                ) : (
                    <div
                        className="w-8 h-8 rounded-full bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 flex items-center justify-center">
                    <span className="text-white text-lg font-bold">
                      {displayName.charAt(0).toUpperCase()}
                    </span>
                    </div>
                )}
              </div>
            </div>
            <div className="ml-3">
              {GroupChatDetails()}
              <div className="text-xs text-base-content opacity-70">
                {selectedChannel?.name || 'Канал не выбран'}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <button
              className={`btn btn-sm mr-2 text-xs ${localScenarioStatus ? 'btn-error' : 'btn-success'}`}
              onClick={handleScenarioToggle}
              disabled={isTogglingScenario || localScenarioStatus === null}
          >
            {isTogglingScenario ? (
                <ImSpinner2 className="animate-spin mr-1"/>
            ) : localScenarioStatus ? (
                <FaStop className="mr-1"/>
            ) : (
                <FaPlay className="mr-1"/>
            )}
            {localScenarioStatus ? 'AI' : 'AI'}
          </button>
        </div>
      </div>

      <div className="border-t border-base-300 dark:border-base-700">
        <div className="p-4">
          <div className="flex items-center bg-base-200 dark:bg-base-800 rounded-lg p-2">
            <FiSearch className="text-base-content mr-2" />
            <input
              type="text"
              placeholder="Поиск в сообщениях..."
              className="bg-transparent border-none focus:outline-none w-full text-base-content text-xs"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-y-auto p-4 relative scroll-container" ref={messagesContainerRef}>
        <AnimatePresence>
          {Object.entries(groupedMessages).map(([date, messages]) => (
            <React.Fragment key={date}>
              <div className="text-center my-4">
                <span className="bg-base-200 dark:bg-base-700 text-base-content text-xs px-2 py-1 rounded-full">
                  {formatDateHeader(date)}
                </span>
              </div>
              {messages.map((msg, index) => (
                  <motion.div
                      key={msg.id || index}
                      variants={messageVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition={{ duration: 0.3 }}
                      className={`chat ${msg.user_id ? 'chat-end' : 'chat-start'} mb-2`}
                  >
                    <button
                        onClick={() => handleShowUserModal(msg)}
                        className="chat-header text-xs font-bold truncate text-base-content mb-1"
                    >
                      {renderName(msg)}
                    </button>


                    <div
                        className={`chat-bubble ${msg.user_id ? 'bg-primary text-primary-content' : 'bg-base-200 dark:bg-base-700 text-base-content'} max-w-[80%] ${msg.file ? 'p-0 overflow-hidden' : ''}`}
                    >

                      {msg.message && typeof msg.message === 'string' && (
                          <div className="break-words text-md whitespace-pre-wrap max-w-prose">
                            {msg.message.split('\n').map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i !== msg.message.split('\n').length - 1 && <br />}
                                </React.Fragment>
                            ))}
                          </div>
                      )}


                      {msg.file && (
                          <div className="mt-0">
                            {renderFileContent(msg.file)}
                          </div>
                      )}
                    </div>

                    <div className="chat-footer text-xs opacity-50 mt-1">
                      <time className="text-base-content">{formatDate(msg.created_at)}</time>
                      {msg.user_id && (
                          <span className={`ml-2 ${msg.viewed ? 'text-primary' : ''}`}>
          {msg.viewed ? '✓✓' : '✓'}
        </span>
                      )}
                    </div>
                  </motion.div>
              ))}

            </React.Fragment>
          ))}
        </AnimatePresence>
        {!isAtBottom && (
            <button
                className="btn btn-circle btn-sm fixed bottom-40 right-8 bg-primary text-primary-content"
                onClick={scrollToBottom}
            >
              <FaArrowDown/>
            </button>
        )}
      </div>

      <div className="p-4 border-t border-base-300 dark:border-base-700">
        {selectedFile && (
            <div className="mb-2 p-2 bg-base-200 dark:bg-base-700 rounded-lg flex items-center justify-between">
              <span className="text-xs text-base-content truncate">{selectedFile.name}</span>
              <button onClick={removeSelectedFile} className="btn btn-ghost btn-xs">
                <FaTimes/>
              </button>
            </div>
        )}
        <div className="flex items-center space-x-2">
          <textarea
              ref={textareaRef}
              placeholder="Напишите сообщение..."
              className="textarea w-full bg-base-200 dark:bg-base-700 text-base-content text-[16px] resize-none overflow-hidden"
              style={{
                height: '48px',
                minHeight: '48px',
                maxHeight: '96px'
              }}
              value={message}
            onChange={handleTextareaChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isSending}
          />

          <button
            className="btn btn-circle btn-md btn-primary"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            <FaSmile />
          </button>
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label htmlFor="file-upload" className="btn btn-circle btn-md btn-primary">
            <FaDownload />
          </label>
          <button
            className="btn btn-circle btn-primary"
            onClick={handleSendMessage}
            disabled={isSending}
          >
            {isSending ? (
              <ImSpinner2 className="animate-spin" />
            ) : (
              <FaArrowUp />
            )}
          </button>
        </div>
        {showEmojiPicker && (
          <div className="absolute bottom-20 right-4" ref={emojiPickerRef}>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
      {selectedImage && (
        <ImagePopup
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default ChatWindow;

import React, {useMemo, useState, useCallback, useEffect} from 'react';
import { GrFlows } from "react-icons/gr";
import {FiSearch, FiChevronLeft, FiPlus} from "react-icons/fi";
import api from "./api";

const ChatList = ({ dialogs, onSelectDialog, onBack, selectedDialogId, unreadDialogs }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClientsLoaded, setIsClientsLoaded] = useState(false);

  const sortDialogs = useCallback((dialogsToSort) => {
    return [...dialogsToSort].sort((a, b) => {
      const timeA = new Date(a.lastMessageTime ?? 0).getTime();
      const timeB = new Date(b.lastMessageTime ?? 0).getTime();
      return timeB - timeA;
    });
  }, []);

  const sortedAndFilteredDialogs = useMemo(() => {
    if (!Array.isArray(dialogs)) return [];
  
    return dialogs.filter(dialog => {
      const fullName = `${dialog.client_first_name || ''} ${dialog.client_last_name || ''}`.trim();
      const searchString = fullName || dialog.name;
      return searchString.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (dialog.lastMessage && dialog.lastMessage.toLowerCase().includes(searchQuery.toLowerCase()));
    });
  }, [dialogs, searchQuery]);

  const formatTime = (timestamp) => {
    if (!timestamp) return '';

    const isUTC = timestamp.endsWith('Z') || /[+-]\d{2}:\d{2}$/.test(timestamp);
    const isoString = isUTC ? timestamp : `${timestamp}Z`;
    const date = new Date(isoString);

    if (isNaN(date)) return '';

    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
    }
  };

  const toggleClientSelection = (client) => {
    setSelectedClients(prevSelectedClients => {
      if (prevSelectedClients.includes(client.id)) {
        // Если клиент уже выбран, снимаем выбор
        return prevSelectedClients.filter(clientId => clientId !== client.id);
      } else {
        // Если клиент не выбран, добавляем его в список выбранных
        return [...prevSelectedClients, client.id];
      }
    });
  };
  const filteredClients = clients.filter(client => client.first_name && client.last_name)
      .filter(client => {
        const fullName = `${client.first_name} ${client.last_name}`.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase());
      });

  const fetchClientsData = async () => {
    try {
      const response = await api.getClients();
      setClients(response.data);
      setIsClientsLoaded(true);
    } catch (err) {
      console.log('Не удалось загрузить данные клиентов');
      setClients(null);
      setIsClientsLoaded(true);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const openModal = () => {
    fetchClientsData();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleOutsideClick = (e) => {
      closeModal();
  };

  return (
      <div
          className="w-full bg-base-100 dark:bg-base-900 h-full flex flex-col text-xs border-r border-base-300 border-l">
        <div className="flex items-center justify-between p-4 border-b border-base-300">
          <div className="flex items-center w-full">
            <button
                className="lg:hidden mr-4 text-base-content hover:text-primary"
                onClick={onBack}
            >
              <FiChevronLeft className="h-6 w-6"/>
            </button>
            <div
                className="text-xs w-full font-semibold text-primary-content bg-primary dark:bg-primary p-2 rounded-lg flex flex-row items-center gap-2 w-full">
              <GrFlows/> Диалоги
            </div>
          </div>
          <button
              className="ml-4 p-1 rounded-full bg-primary text-white hover:bg-primary-dark focus:outline-none"
              aria-label="Добавить новый диалог"
              onClick={openModal}
          >
            <FiPlus className="h-5 w-5"/>
          </button>
        </div>


        {/* Модальное окно с клиентами */}
        {isModalOpen  && isClientsLoaded && (
            <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex items-center justify-center"
                 onClick={handleOutsideClick} >
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full"
                   onClick={(e) => e.stopPropagation()}>
                <h3 className="text-lg font-bold mb-4">Выберите клиента</h3>
                <div className="mb-4">
                  <input
                      type="text"
                      placeholder="Поиск клиента..."
                      className="bg-base-200 p-2 rounded-lg w-full"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                <div className="max-h-64 overflow-y-auto">
                  <ul className="space-y-2">
                    {filteredClients.map(client => (
                        <li key={client.id} className="flex justify-between items-center">
                          <span>{`${client.first_name} ${client.last_name}`}</span>
                          <button
                              className={`px-3 py-1 rounded-lg ${selectedClients.includes(client.id) ? 'bg-primary text-white' : 'bg-base-300'}`}
                              onClick={() => toggleClientSelection(client)}
                          >
                            Выбрать
                          </button>
                        </li>
                    ))}
                  </ul>
                </div>

                <button
                    className="mt-4 w-full bg-primary text-white px-4 py-2 rounded-lg"
                    //добавить создание чата
                    //onClick={() => onCreateChat(selectedClients)}
                    disabled={selectedClients.length === 0}
                >
                  Создать чат
                </button>

              </div>
            </div>
        )}


        <div className="p-4">
          <div className="flex items-center bg-base-200 dark:bg-base-800 rounded-lg p-2">
            <FiSearch className="text-base-content mr-2"/>
            <input
                type="text"
                placeholder="Поиск диалогов..."
                className="bg-transparent border-none focus:outline-none w-full text-base-content"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="overflow-y-auto flex-grow px-4">
          {sortedAndFilteredDialogs.length === 0 ? (
              <div className="text-center p-4 text-base-content">Нет доступных диалогов</div>
          ) : (
              <ul className="menu bg-base-100 dark:bg-base-900 rounded-box text-xs">
                {sortedAndFilteredDialogs.map((dialog) => {
                  const displayName = dialog.client_first_name && dialog.client_last_name
                      ? `${dialog.client_first_name} ${dialog.client_last_name}`
                      : dialog.name;

                  return (
                      <li
                          key={`${dialog.id}-${dialog.lastMessageTime}`}
                          className={`mb-2 text-xs rounded-lg hover:bg-base-200 dark:hover:bg-base-700 last:mb-0 ${
                              dialog.id === selectedDialogId ? 'bg-base-200 dark:bg-base-700' : 'bg-base-100 dark:bg-base-800'
                          }`}
                      >
                        <a
                            onClick={() => onSelectDialog(dialog)}
                            className={`text-base-content flex items-center justify-between p-2 rounded-lg ${
                                dialog.id === selectedDialogId ? 'bg-base-200 dark:bg-base-700' : 'hover:bg-base-200 dark:hover:bg-base-700'
                            }`}
                        >
                          <div className="flex items-center">
                            <div className="avatar">
                              <div
                                  className="w-10 h-10 rounded-full flex items-center justify-center bg-base-300 dark:bg-base-600">
                                {dialog.client_avatar ? (
                                    <img src={dialog.client_avatar} alt="avatar" className="rounded-full"/>
                                ) : (
                                    <div
                                        className="w-10 h-10 rounded-full bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 flex items-center justify-center">
                              <span className="text-white text-lg font-semibold">
                                {displayName.charAt(0).toUpperCase()}
                              </span>
                                    </div>
                                )}
                              </div>
                            </div>
                            <div className="ml-3 max-w-[130px] overflow-hidden">
                              <div className="font-bold text-xs truncate text-base-content">{displayName}</div>
                              <div className="text-xs text-base-content opacity-70 truncate">
                                {dialog.lastMessage || 'Нет сообщений'}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-end">
                            <div className="text-xs text-base-content opacity-50">
                              {dialog.lastMessageTime ? formatTime(dialog.lastMessageTime) : ''}
                            </div>
                            {unreadDialogs.has(dialog.id) && (
                                <div className="bg-primary text-primary-content text-xs rounded-full px-2 py-0.5 mt-1">
                                  Новое
                                </div>
                            )}
                          </div>
                        </a>
                      </li>
                  );
                })}
              </ul>
          )}
        </div>
      </div>
  );
};

export default ChatList;
import React, { useState, useEffect } from 'react';
import { FaSpinner, FaPlusCircle, FaArrowLeft, FaPaperPlane } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from './config';

function Mailings() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [viewMode, setViewMode] = useState(id ? 'details' : 'list');
  const [loading, setLoading] = useState(true);
  const [mailings, setMailings] = useState([]);
  const [channels, setChannels] = useState([]);
  const [newMailing, setNewMailing] = useState({
    name: '',
    channel_id: '',
    message: '',
    contacts: '',
  });
  const [selectedMailing, setSelectedMailing] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [notification, setNotification] = useState(null);
  const [runningMailings, setRunningMailings] = useState(new Set());
  const [contactFilter, setContactFilter] = useState('all');

  // States for Clients Integration
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState(new Set());
  const [availableVariables, setAvailableVariables] = useState([]);

  // State to toggle between CRM selection and manual entry
  const [contactSelectionMode, setContactSelectionMode] = useState('crm'); // 'crm' or 'manual'
  const [manualContacts, setManualContacts] = useState('');

  // State for selected columns to filter clients
  const [clientColumns, setClientColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10; // Number of clients per page

  useEffect(() => {
    fetchMailings();
    fetchChannels();
    fetchClients();
  }, []);

  useEffect(() => {
    if (id && id !== 'create') {
      handleSelectMailing(id);
    } else if (id === 'create') {
      setViewMode('create');
    } else {
      setSelectedMailing(null);
      setViewMode('list');
    }
  }, [id]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedColumns, clientColumns, clients]);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const fetchMailings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(config.API_MAILINGS);
      setMailings(response.data);
    } catch (error) {
      console.error('Error fetching mailings:', error);
      showNotification('Ошибка при загрузке рассылок', 'error');
    }
    setLoading(false);
  };

  const fetchChannels = async () => {
    try {
      const response = await axios.get(config.API_CHANNELS);
      setChannels(response.data);
    } catch (error) {
      console.error('Error fetching channels:', error);
      showNotification('Ошибка при загрузке каналов', 'error');
    }
  };

  // Fetch Clients from CRM
  const fetchClients = async () => {
    try {
      const response = await axios.get(config.API_CLIENTS);
      setClients(response.data);

      // Отладка: вывод первого клиента
      if (response.data.length > 0) {
        console.log('Sample client:', response.data[0]);
      }

      // Dynamically determine available variables based on client data
      if (response.data.length > 0) {
        const sampleClient = response.data[0];
        const variables = Object.keys(sampleClient).map(key => `{${key}}`);
        setAvailableVariables(variables);

        const columns = Object.keys(sampleClient);
        setClientColumns(columns);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      showNotification('Ошибка при загрузке клиентов', 'error');
    }
  };

  const handleCreateMailing = async (e) => {
    e.preventDefault();
    try {
      let contacts = '';

      if (contactSelectionMode === 'crm') {
        // Prepare contacts from selected CRM clients
        contacts = Array.from(selectedClients)
          .map(client => {
            let contact = '';
            if (client.phone) {
              contact = client.phone;
            } else if (client.login) {
              contact = '@' + client.login; // Add '@' to the login
            } else {
              contact = ''; // Если нет ни телефона, ни логина
            }
            return contact.trim();
          })
          .filter(contact => contact) // Ensure contact is not undefined/null
          .join('\n');
      } else if (contactSelectionMode === 'manual') {
        // Use manually entered contacts
        const enteredContacts = manualContacts
          .split('\n')
          .map(contact => contact.trim())
          .filter(contact => contact !== '');
        contacts = enteredContacts.join('\n');
      }

      if (!contacts) {
        showNotification('Не выбраны контакты для рассылки', 'error');
        return;
      }

      const mailingData = { ...newMailing, contacts };

      console.log('Mailing data:', mailingData); // Отладка

      await axios.post(`${config.API_MAILINGS}/create`, mailingData);
      setNewMailing({ name: '', channel_id: '', message: '', contacts: '' });
      setSelectedClients(new Set());
      setManualContacts('');
      fetchMailings();
      setViewMode('list');
      navigate('/main/mailings');
      showNotification('Рассылка успешно создана!');
    } catch (error) {
      console.error('Error creating mailing:', error);
      showNotification('Ошибка при создании рассылки', 'error');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMailing(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectMailing = async (id) => {
    setLoadingDetails(true);
    try {
      const response = await axios.get(`${config.API_MAILINGS}/${id}`);
      console.log('Selected Mailing Data:', response.data); // Для отладки
      setSelectedMailing(response.data);
      setViewMode('details');
      navigate(`/main/mailings/${id}`);
    } catch (error) {
      console.error('Error fetching mailing details:', error);
      showNotification('Ошибка при загрузке деталей рассылки', 'error');
      navigate('/main/mailings');
    }
    setLoadingDetails(false);
  };

  const handleBackToList = () => {
    setSelectedMailing(null);
    setViewMode('list');
    navigate('/main/mailings');
  };

  const handleRunMailing = async (id) => {
    setRunningMailings(prev => new Set(prev).add(id));
    try {
      await axios.post(`${config.API_MAILINGS}/${id}/run`);
      fetchMailings();
      showNotification('Рассылка успешно запущена!');
    } catch (error) {
      console.error('Error running mailing:', error);
      showNotification('Ошибка при запуске рассылки', 'error');
    }
    setRunningMailings(prev => {
      const newSet = new Set(prev);
      newSet.delete(id);
      return newSet;
    });
  };

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const handleContactFilterChange = (e) => {
    setContactFilter(e.target.value);
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
    if (mode === 'list') {
      setSelectedMailing(null);
      navigate('/main/mailings');
    } else if (mode === 'create') {
      setSelectedMailing(null);
      navigate('/main/mailings/create');
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'new': return 'Новый';
      case 'in_work': return 'В работе';
      case 'completed': return 'Завершен';
      default: return status;
    }
  };

  const translateContactStatus = (status) => {
    switch (status) {
      case 'in_queue': return 'В очереди';
      case 'sent': return 'Отправлено';
      case 'error': return 'Ошибка';
      default: return status;
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const calculateStats = (contactsArray) => {
    const total = contactsArray.length;
    const successful = contactsArray.filter(contact => contact.status === 'sent').length;
    const cr = total > 0 ? ((successful / total) * 100).toFixed(2) : 0;
    return { total, successful, cr };
  };

  // Функция для получения массива контактов независимо от их структуры
  const getContactsArray = (contacts) => {
    if (Array.isArray(contacts)) {
      // Предполагается, что contacts - массив объектов с полями phone/login, status, result_message
      return contacts.map(contact => ({
        contact: contact.phone || contact.login || contact,
        status: contact.status || 'in_queue',
        result_message: contact.result_message || '—',
      }));
    } else if (typeof contacts === 'string') {
      return contacts.split('\n').map(contact => ({
        contact: contact.trim(),
        status: 'in_queue', // Можно заменить на реальные данные, если они есть
        result_message: '—',
      })).filter(contact => contact.contact !== '');
    } else {
      return [];
    }
  };

  // Фильтрация рассылок по статусу
  const filteredMailings = mailings.filter((mailing) => {
    // Фильтрация по статусу
    if (filterStatus !== 'all' && mailing.status !== filterStatus) {
      return false;
    }

    return true;
  });

  // Фильтрация клиентов по выбранным столбцам
  const filteredClients = clients.filter(client => {
    return selectedColumns.every(column => {
      const value = client[column];
      return value !== undefined && value !== null && value !== '';
    });
  });

  // Пагинация для клиентов
  const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex-1 p-4 w-full relative overflow-y-auto">
      {notification && (
        <div className="absolute top-0 left-0 right-0 z-50 animate-fade-in-down">
          <div className={`alert ${notification.type === 'error' ? 'alert-error' : 'alert-success'} shadow-lg`}>
            <div>
              <span>{notification.message}</span>
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-full mx-auto">
        <h1 className='text-2xl font-bold text-base-content mb-6'>Рассылки</h1>
        
        <div className="flex justify-around mb-6">
          <button
            onClick={() => toggleViewMode('create')}
            className={`btn btn-primary flex-1 ${viewMode === 'create' ? 'btn-active' : ''}`}
          >
            Создать рассылку
          </button>
          <button
            onClick={() => toggleViewMode('list')}
            className={`btn btn-primary flex-1 ml-4 ${viewMode === 'list' ? 'btn-active' : ''}`}
          >
            Список рассылок
          </button>
        </div>

        {viewMode === 'create' && (
          <div className="card bg-base-100 dark:bg-base-800 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-base-content">Создать новую рассылку</h2>
              <form onSubmit={handleCreateMailing} className="space-y-4">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-base-content">Название рассылки</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={newMailing.name}
                    onChange={handleInputChange}
                    required
                    placeholder="Введите название рассылки"
                    className="input input-bordered w-full bg-base-100 dark:bg-base-700 text-base-content"
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-base-content">Канал</span>
                  </label>
                  <select
                    name="channel_id"
                    value={newMailing.channel_id}
                    onChange={handleInputChange}
                    required
                    className="select select-bordered w-full bg-base-100 dark:bg-base-700 text-base-content"
                  >
                    <option value="" disabled>Выберите канал</option>
                    {channels
                      .filter((channel) => channel.integration_status !== 'disabled')
                      .map((channel) => (
                        <option key={channel.id} value={channel.id}>
                          {channel.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-base-content">Сообщение</span>
                  </label>
                  <textarea
                    name="message"
                    value={newMailing.message}
                    onChange={handleInputChange}
                    required
                    placeholder="Введите сообщение"
                    className="textarea textarea-bordered h-16 bg-base-100 dark:bg-base-700 text-base-content"
                  />
                  <div className="mt-2 text-base-content">
                    <strong>Доступные переменные:</strong> {availableVariables.join(', ')}
                  </div>
                </div>
                
                {/* Выбор способа добавления контактов */}
                <div className="form-control">
                  <label className="label">
                    <span className="label-text text-base-content">Источник контактов</span>
                  </label>
                  <select
                    value={contactSelectionMode}
                    onChange={(e) => setContactSelectionMode(e.target.value)}
                    className="select select-bordered w-full bg-base-100 dark:bg-base-700 text-base-content"
                  >
                    <option value="crm">CRM</option>
                    <option value="manual">Ручной ввод</option>
                  </select>
                </div>

                {contactSelectionMode === 'crm' ? (
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text text-base-content">Выберите столбцы для фильтрации</span>
                    </label>
                    
                    {/* Чекбоксы для выбора столбцов */}
                    <div className="mb-4">
                      <div className="flex flex-wrap">
                        {clientColumns.map(column => (
                          <label key={column} className="cursor-pointer label justify-start mr-4 mb-2">
                            <input
                              type="checkbox"
                              className="checkbox checkbox-primary mr-2"
                              checked={selectedColumns.includes(column)}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setSelectedColumns(prev => {
                                  if (checked) {
                                    return [...prev, column];
                                  } else {
                                    return prev.filter(c => c !== column);
                                  }
                                });
                              }}
                            />
                            <span className="label-text text-base-content">{column}</span>
                          </label>
                        ))}
                      </div>
                    </div>

                    {/* Кнопки для выбора всех отфильтрованных клиентов */}
                    <div className="mb-4">
                      <button
                        onClick={() => {
                          const newSet = new Set(selectedClients);
                          filteredClients.forEach(client => newSet.add(client));
                          setSelectedClients(newSet);
                        }}
                        type="button"
                        className="btn btn-primary mr-2"
                      >
                        Выбрать всех отфильтрованных клиентов
                      </button>
                      <button
                        onClick={() => {
                          const newSet = new Set(selectedClients);
                          filteredClients.forEach(client => newSet.delete(client));
                          setSelectedClients(newSet);
                        }}
                        type="button"
                        className="btn btn-secondary"
                      >
                        Убрать всех отфильтрованных клиентов
                      </button>
                    </div>

                    {/* Таблица клиентов с пагинацией */}
                    <div className="overflow-x-auto">
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="checkbox checkbox-primary"
                                checked={currentClients.length > 0 && currentClients.every(client => selectedClients.has(client))}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  if (checked) {
                                    const newSet = new Set(selectedClients);
                                    currentClients.forEach(client => newSet.add(client));
                                    setSelectedClients(newSet);
                                  } else {
                                    const newSet = new Set(selectedClients);
                                    currentClients.forEach(client => newSet.delete(client));
                                    setSelectedClients(newSet);
                                  }
                                }}
                              />
                            </th>
                            {clientColumns.map(column => (
                              <th key={column}>{column}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="text-base-content">
                          {currentClients.map(client => (
                            <tr key={client.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-primary"
                                  checked={selectedClients.has(client)}
                                  onChange={(e) => {
                                    const newSet = new Set(selectedClients);
                                    if (e.target.checked) {
                                      newSet.add(client);
                                    } else {
                                      newSet.delete(client);
                                    }
                                    setSelectedClients(newSet);
                                  }}
                                />
                              </td>
                              {clientColumns.map(column => (
                                <td key={column}>{client[column]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Пагинация */}
                    <div className="flex justify-center mt-4">
                      <ul className="inline-flex items-center -space-x-px">
                        <li>
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50"
                          >
                            Назад
                          </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                          <li key={page}>
                            <button
                              onClick={() => handlePageChange(page)}
                              className={`px-3 py-2 leading-tight ${
                                page === currentPage
                                  ? 'text-blue-600 bg-blue-50 border border-blue-300'
                                  : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                              }`}
                            >
                              {page}
                            </button>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 disabled:opacity-50"
                          >
                            Вперед
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text text-base-content">Введите контакты вручную</span>
                    </label>
                    <textarea
                      value={manualContacts}
                      onChange={(e) => setManualContacts(e.target.value)}
                      placeholder="Каждый номер с новой строки или телеграмм логин с @"
                      className="textarea textarea-bordered h-32 bg-base-100 dark:bg-base-700 text-base-content"
                    />
                  </div>
                )}

                <div className="card-actions justify-end">
                  <button type="submit" className="btn btn-primary">
                    <FaPlusCircle className="mr-2" />
                    Создать рассылку
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {viewMode === 'list' && (
          <div className="card bg-base-100 dark:bg-base-800 shadow-xl h-[83%]">
            <div className="card-body overflow-hidden h-[80%]">
              {/* Фильтр по статусу */}
              <div className="mb-4">
                <div className="form-control w-full sm:w-1/4">
                  <label className="label">
                    <span className="label-text text-base-content">Статус:</span>
                  </label>
                  <select
                    value={filterStatus}
                    onChange={handleFilterChange}
                    className="select select-bordered w-full bg-base-100 dark:bg-base-700 text-base-content"
                  >
                    <option value="all">Все</option>
                    <option value="new">Новые</option>
                    <option value="in_work">В работе</option>
                    <option value="completed">Завершенные</option>
                  </select>
                </div>
              </div>

              {/* Список рассылок */}
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <FaSpinner className="animate-spin text-primary" size={24} />
                </div>
              ) : (
                <>
                  <div className="overflow-x-auto overflow-y-auto max-h-[calc(100vh-300px)]">
                    <table className="table w-full">
                      <thead>
                        <tr className="bg-base-300 dark:bg-base-700">
                          <th className="text-base-content">Название</th>
                          <th className="text-base-content">Канал</th>
                          <th className="text-base-content">Статус</th>
                          <th className="text-base-content">Дата создания</th>
                          <th className="text-base-content">Действия</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredMailings.map((mailing) => (
                          <tr key={mailing.id} className="hover:bg-base-300 dark:hover:bg-base-700">
                            <td>
                              <a
                                className="link link-hover text-base-content"
                                onClick={() => handleSelectMailing(mailing.id)}
                              >
                                {mailing.name}
                              </a>
                            </td>
                            <td>
                              {channels.find(channel => channel.id === mailing.channel_id)?.name || 'Неизвестно'}
                            </td>
                            <td>
                              <span className={`badge ${
                                mailing.status === 'completed'
                                  ? 'badge-success'
                                  : mailing.status === 'in_work'
                                  ? 'badge-warning'
                                  : 'badge-info'
                              }`}>
                                {translateStatus(mailing.status)}
                              </span>
                            </td>
                            <td className="text-base-content">
                              {formatDate(mailing.created_at)}
                            </td>
                            <td>
                              <button
                                onClick={() => handleRunMailing(mailing.id)}
                                className={`btn btn-sm w-[17vh] ${
                                  mailing.status === 'new' && !runningMailings.has(mailing.id)
                                    ? 'btn-primary'
                                    : 'btn-disabled'
                                }`}
                                disabled={mailing.status !== 'new' || runningMailings.has(mailing.id)}
                              >
                                {runningMailings.has(mailing.id) ? (
                                  <FaSpinner className="animate-spin mr-2" />
                                ) : (
                                  <FaPaperPlane className="mr-2" />
                                )}
                                {runningMailings.has(mailing.id) ? 'Запуск...' : 'Запустить'}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {viewMode === 'details' && selectedMailing && (
          <div className="card bg-base-100 dark:bg-base-800 shadow-xl h-[83%]">
            <div className="card-body overflow-hidden h-[80%]">
              <button
                onClick={handleBackToList}
                className="btn btn-ghost mb-4 text-base-content"
              >
                <FaArrowLeft className="mr-2 text-base-content" /> Назад к списку рассылок
              </button>
              {loadingDetails ? (
                <div className="flex justify-center items-center h-64">
                  <FaSpinner className="animate-spin text-primary" size={24} />
                </div>
              ) : (
                <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
                  <h2 className="text-2xl font-bold mb-4 text-base-content">{selectedMailing.name}</h2>
                  <p className="mb-2 text-base-content">
                    <strong>Статус:</strong>
                    <span className={`ml-2 badge ${
                      selectedMailing.status === 'completed'
                        ? 'badge-success'
                        : selectedMailing.status === 'in_work'
                        ? 'badge-warning'
                        : 'badge-info'
                    }`}>
                      {translateStatus(selectedMailing.status)}
                    </span>
                  </p>
                  <p className="mb-2 text-base-content">
                    <strong>Канал:</strong>{' '}
                    <button 
                      onClick={() => navigate(`/main/chats/${selectedMailing.channel_id}`)}
                      className="link link-primary"
                    >
                      Перейти к каналу
                    </button>
                  </p>
                  <p className="mb-4 text-base-content"><strong>Сообщение:</strong> {selectedMailing.message}</p>
                  
                  {selectedMailing.contacts && (
                    <div className="mb-6 p-6 bg-base-200 dark:bg-base-700 rounded-lg shadow-md">
                      <h3 className="text-2xl font-semibold mb-4 text-base-content">Статистика рассылки</h3>
                      {(() => {
                        const contactsArray = getContactsArray(selectedMailing.contacts);
                        const { total, successful, cr } = calculateStats(contactsArray);
                        return (
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="stat bg-base-100 dark:bg-base-600 rounded-lg p-4">
                              <div className="stat-figure text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                              </div>
                              <div className="stat-title text-base-content">Всего контактов</div>
                              <div className="stat-value text-primary">{total}</div>
                            </div>
                            <div className="stat bg-base-100 dark:bg-base-600 rounded-lg p-4">
                              <div className="stat-figure text-success">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
                                </svg>
                              </div>
                              <div className="stat-title text-base-content">Успешно отправлено</div>
                              <div className="stat-value text-success">{successful}</div>
                            </div>
                            <div className="stat bg-base-100 dark:bg-base-600 rounded-lg p-4">
                              <div className="stat-figure text-info">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path>
                                </svg>
                              </div>
                              <div className="stat-title text-base-content">CR</div>
                              <div className="stat-value text-info">{cr}%</div>
                            </div>
                          </div>
                        );
                      })()}
                    </div>
                  )}
                  
                  <h3 className="text-xl font-semibold mb-2 text-base-content">Контакты</h3>
                  
                  <div className="mb-4">
                    <label className="label">
                      <span className="label-text text-base-content">Фильтр контактов:</span>
                    </label>
                    <select
                      value={contactFilter}
                      onChange={handleContactFilterChange}
                      className="select select-bordered w-full max-w-xs bg-base-100 dark:bg-base-700 text-base-content"
                    >
                      <option value="all">Все</option>
                      <option value="sent">Успешно отправленные</option>
                      <option value="error">Ошибки</option>
                    </select>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      <thead>
                        <tr className="bg-base-300 dark:bg-base-700">
                          <th className="text-base-content">Контакт</th>
                          <th className="text-base-content">Статус</th>
                          <th className="text-base-content">Результат</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getContactsArray(selectedMailing.contacts)
                          .filter(contact => {
                            if (contactFilter === 'all') return true;
                            return contact.status === contactFilter;
                          })
                          .map((contactObj, index) => (
                            <tr key={index} className="hover:bg-base-300 dark:hover:bg-base-700">
                              <td className="text-base-content">{contactObj.contact}</td>
                              <td>
                                <span className={`badge ${
                                  contactObj.status === 'sent'
                                    ? 'badge-success'
                                    : contactObj.status === 'error'
                                    ? 'badge-error'
                                    : 'badge-info'
                                }`}>
                                  {translateContactStatus(contactObj.status)}
                                </span>
                              </td>
                              <td className="text-base-content">{contactObj.result_message}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mailings;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineFilter,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineSave,
  AiOutlineUpload
} from "react-icons/ai";
import config from './config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [columns, setColumns] = useState([]);
  const [buildColumns, setBuildColumns] = useState([]); // столбцы из билдера
  const [editedClients, setEditedClients] = useState({});
  const [editingColumn, setEditingColumn] = useState(null);
  const [newColumnData, setNewColumnData] = useState({ name: '', type: 'string', comment: '' });
  const [loading, setLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});
  const clientsPerPage = 20;

  const reservedColumns = ['id', 'phone', 'login', 'inn', 'last_name', 'first_name'];

  const dataTypes = [
    { value: 'string', label: 'Текст (короткий)' },
    { value: 'integer', label: 'Целое число' },
    { value: 'text', label: 'Текст (длинный)' },
    { value: 'timestampTz', label: 'Дата и время' }
  ];

  const fileInputRef = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      // Загружаем данные клиентов
      const clientsResponse = await axios.get(config.API_CLIENTS);
      setClients(clientsResponse.data);

      // Загружаем структуру столбцов из билдера
      const buildResponse = await axios.get('https://nikta.ai/api/clients/build');
      setBuildColumns(buildResponse.data);

      // Комбинируем зарезервированные столбцы и столбцы из билдера
      const allColumns = [
        ...reservedColumns.map(name => ({ name, comment: name, reserved: true })),
        ...buildResponse.data
      ];
      setColumns(allColumns);

      // Инициализируем фильтры на основе загруженных столбцов
      const initialFilters = {};
      allColumns.forEach(column => {
        initialFilters[column.name] = false;
      });
      setFilters(initialFilters);

      console.log('Загружены клиенты:', clientsResponse.data);
      console.log('Загружены столбцы из билдера:', buildResponse.data);
      console.log('Все столбцы:', allColumns);
    } catch (error) {
      setError(error);
      toast.error(`Ошибка при загрузке данных: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Обновляем фильтры при изменении столбцов
  useEffect(() => {
    const updatedFilters = {};
    columns.forEach(column => {
      updatedFilters[column.name] = filters[column.name] || false;
    });
    setFilters(updatedFilters);
  }, [columns]);

  const updateTable = async (updatedColumns) => {
    try {
      const payload = {
        columns: updatedColumns.map(col => ({
          name: col.name,
          type: col.type || 'string',
          comment: col.comment
        }))
      };

      console.log('Отправка данных на обновление структуры таблицы:', payload);

      const response = await axios.post('https://nikta.ai/api/clients/build/update', payload);
      console.log('Ответ сервера:', response.data);

      toast.success('Структура таблицы успешно обновлена');

      // Повторно загружаем данные после обновления структуры
      await fetchData();
    } catch (error) {
      console.error('Ошибка при обновлении структуры таблицы:', error);
      toast.error(`Ошибка при обновлении структуры таблицы: ${error.message}`);
      throw error;
    }
  };

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(filteredClients.length / clientsPerPage) - 1) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleFilterChange = (e) => {
    const { name, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: checked
    }));
    setCurrentPage(0);
  };

  const handleClearFilters = () => {
    const resetFilters = {};
    Object.keys(filters).forEach(key => {
      resetFilters[key] = false;
    });
    setFilters(resetFilters);
    setSearchTerm('');
    setCurrentPage(0);
  };

  const handleAddColumn = async () => {
    if (!/^[a-zA-Z0-9_]+$/.test(newColumnData.name)) {
      toast.error('Имя столбца должно содержать только латинские буквы, цифры и знак подчеркивания');
      return;
    }
    try {
      const updatedBuildColumns = [...buildColumns, newColumnData];

      setBuildColumns(updatedBuildColumns);
      setNewColumnData({ name: '', type: 'string', comment: '' });
      setIsAddingColumn(false);

      // Обновляем таблицу с новой структурой
      await updateTable(updatedBuildColumns);
    } catch (error) {
      console.error('Ошибка при добавлении столбца:', error);
      toast.error('Ошибка при добавлении столбца');
    }
  };

  const handleUpdateColumn = async (updatedColumn) => {
    try {
      const updatedBuildColumns = buildColumns.map(col =>
        col.name === updatedColumn.name ? updatedColumn : col
      );

      setBuildColumns(updatedBuildColumns);
      setEditingColumn(null);

      // Обновляем таблицу с обновленной структурой
      await updateTable(updatedBuildColumns);
    } catch (error) {
      console.error('Ошибка при обновлении столбца:', error);
      toast.error('Ошибка при обновлении столбца');
    }
  };

  const handleEditColumn = (column) => {
    if (column.reserved) {
      toast.error('Нельзя редактировать зарезервированный столбец');
      return;
    }
    setEditingColumn(column);
  };

  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
    if (isEditMode) {
      setEditedClients({});
    }
  };

  const handleCellEdit = (clientId, columnName, value) => {
    setEditedClients(prev => ({
      ...prev,
      [clientId]: {
        ...prev[clientId],
        [columnName]: value
      }
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const updatedClients = clients.map(client => ({
        ...client,
        ...editedClients[client.id]
      }));
      setClients(updatedClients);
      setEditedClients({});
      setIsEditMode(false);
      // Здесь вы можете добавить код для сохранения изменений данных клиентов, если API это поддерживает
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
      toast.error('Ошибка при сохранении изменений');
    }
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setIsImporting(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post('https://nikta.ai/api/clients/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const importedData = response.data;
      setClients(importedData);

      // Обновляем столбцы на основе импортированных данных
      if (importedData.length > 0) {
        const importedColumnNames = Object.keys(importedData[0]).filter(name => !reservedColumns.includes(name));
        const updatedBuildColumns = importedColumnNames.map(name => ({
          name,
          comment: name // Можно обновить комментарии если нужно
        }));
        setBuildColumns(updatedBuildColumns);

        // Обновляем таблицу после импорта данных
        await updateTable(updatedBuildColumns);
      }

      toast.success('Файл успешно импортирован');
    } catch (error) {
      console.error('Ошибка при импорте файла:', error);
      toast.error('Ошибка при импорте файла');
    } finally {
      setIsImporting(false);
    }
  };

  const filteredClients = clients.filter(client => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = columns.some(column => {
      const value = client[column.name];
      return value && value.toString().toLowerCase().includes(searchLower);
    });

    const matchesFilter = Object.entries(filters).every(([key, value]) => {
      if (!value) return true;
      const clientValue = client[key];
      return clientValue && clientValue !== '';
    });

    return matchesSearch && matchesFilter;
  });

  const displayedClients = filteredClients.slice(
    currentPage * clientsPerPage,
    (currentPage + 1) * clientsPerPage
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
        <ToastContainer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-error">
        Ошибка: {error.message}
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 text-base-content h-full">
      <ToastContainer />
      <div className="flex flex-col md:flex-row gap-4 mb-6 ">
        <input
          type="text"
          placeholder="Поиск..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full md:w-1/2"
        />
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="btn btn-primary"
        >
          <AiOutlineFilter className="mr-2" /> {isFilterOpen ? 'Скрыть фильтры' : 'Показать фильтры'}
        </button>

        <button
          onClick={handleEditModeToggle}
          className={`btn ${isEditMode ? 'btn-error' : 'btn-info'}`}
        >
          <AiOutlineEdit className="mr-2" /> {isEditMode ? 'Отменить редактирование' : 'Редактировать'}
        </button>
        {isEditMode && (
          <button
            onClick={handleSaveChanges}
            className="btn btn-success"
          >
            <AiOutlineSave className="mr-2" /> Сохранить изменения
          </button>
        )}

        {/* Кнопка импорта CSV */}
        <button
          onClick={handleImportClick}
          className={`btn btn-accent ${isImporting ? 'loading' : ''}`}
          disabled={isImporting}
        >
          <AiOutlineUpload className="mr-2" /> Импортировать CSV
        </button>
      </div>

      {/* Скрытый input для выбора файла */}
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {isFilterOpen && (
        <div className="bg-base-200 p-4 rounded-lg mb-6">
          <h2 className="text-lg font-semibold mb-4">Фильтры</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
            {Object.entries(filters).map(([key, value]) => (
              <label key={key} className="flex items-center">
                <input
                  type="checkbox"
                  name={key}
                  checked={value}
                  onChange={handleFilterChange}
                  className="checkbox checkbox-primary mr-2"
                />
                <span>{columns.find(col => col.name === key)?.comment || key}</span>
              </label>
            ))}
          </div>
          <button
            onClick={handleClearFilters}
            className="btn btn-outline btn-sm mt-4"
          >
            Очистить фильтры
          </button>
        </div>
      )}

      <div className="bg-base-200 rounded-lg shadow mb-6">
        <div className="overflow-x-auto overflow-y-auto" style={{ maxHeight: '63vh' }}>
          <table className="table w-full table-pin-rows table-pin-cols">
            <thead>
              <tr className="bg-base-200">
                {columns.map(column => (
                  <th key={column.name} className="px-4 py-2 bg-base-300">
                    <div className="flex items-center justify-between">
                      <span>{column.comment}</span>
                      {isEditMode && !column.reserved && (
                        <button
                          className="btn btn-ghost btn-xs"
                          onClick={() => handleEditColumn(column)}
                        >
                          <AiOutlineEdit />
                        </button>
                      )}
                    </div>
                  </th>
                ))}
                {isEditMode && (
                  <th className="px-4 py-2">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => setIsAddingColumn(true)}
                    >
                      <AiOutlinePlus className="mr-1" /> Добавить столбец
                    </button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayedClients.map((client) => (
                <tr key={client.id}>
                  {columns.map(column => (
                    <td key={`${client.id}-${column.name}`} className="px-4 py-2">
                      {isEditMode && !column.reserved ? (
                        <input
                          type="text"
                          value={editedClients[client.id]?.[column.name] ?? client[column.name] ?? ''}
                          onChange={(e) => handleCellEdit(client.id, column.name, e.target.value)}
                          className="input input-bordered input-sm w-full"
                        />
                      ) : (
                        <span>{client[column.name]}</span>
                      )}
                    </td>
                  ))}
                  {isEditMode && <td></td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-between items-center pb-4">
        <span>
          Найдено: {filteredClients.length}
        </span>
        <div className="join">
          <button
            onClick={() => handlePageChange('prev')}
            disabled={currentPage === 0}
            className="join-item btn"
          >
            <AiOutlineArrowLeft />
          </button>
          <button className="join-item btn">
            {currentPage + 1} из {Math.ceil(filteredClients.length / clientsPerPage)}
          </button>
          <button
            onClick={() => handlePageChange('next')}
            disabled={currentPage >= Math.ceil(filteredClients.length / clientsPerPage) - 1}
            className="join-item btn"
          >
            <AiOutlineArrowRight />
          </button>
        </div>
      </div>

      {(isAddingColumn || editingColumn) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-base-100 p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-lg font-bold mb-4">
              {isAddingColumn ? 'Добавить новый столбец' : 'Редактировать столбец'}
            </h3>
            {isAddingColumn && (
              <>
                <input
                  type="text"
                  placeholder="Имя столбца (только латиница, цифры и _)"
                  value={newColumnData.name}
                  onChange={(e) => setNewColumnData({ ...newColumnData, name: e.target.value })}
                  className="input input-bordered w-full mb-4"
                  pattern="[a-zA-Z0-9_]+"
                />
                <select
                  value={newColumnData.type}
                  onChange={(e) => setNewColumnData({ ...newColumnData, type: e.target.value })}
                  className="select select-bordered w-full mb-4"
                >
                  {dataTypes.map(type => (
                    <option key={type.value} value={type.value}>{type.label}</option>
                  ))}
                </select>
              </>
            )}
            <input
              type="text"
              placeholder="Название столбца (для отображения)"
              value={isAddingColumn ? newColumnData.comment : (editingColumn?.comment || '')}
              onChange={(e) => isAddingColumn
                ? setNewColumnData({ ...newColumnData, comment: e.target.value })
                : setEditingColumn({ ...editingColumn, comment: e.target.value })
              }
              className="input input-bordered w-full mb-4"
            />
            <div className="flex justify-end gap-2">
              <button
                className="btn btn-primary"
                onClick={isAddingColumn ? handleAddColumn : () => handleUpdateColumn(editingColumn)}
              >
                {isAddingColumn ? 'Добавить' : 'Сохранить'}
              </button>
              <button
                className="btn btn-ghost"
                onClick={() => isAddingColumn ? setIsAddingColumn(false) : setEditingColumn(null)}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaComments, FaUsers, FaUserCircle, FaCog, FaSignOutAlt, FaBars, FaClipboardList, FaBell, FaPalette, FaTimes } from 'react-icons/fa';
import { IoMdMail } from "react-icons/io";
import AuthContext from './AuthContext';
import './Header.css';
import echoInstance from './EchoGlobal'
import { FaFileAlt } from 'react-icons/fa';

function Header() {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [notifications, setNotifications] = useState([]);
  const [archivedNotifications, setArchivedNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isArchiveView, setIsArchiveView] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [moodStats, setMoodStats] = useState({});
  const [selectedMood, setSelectedMood] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme') || 'cupcake');

  const themes = [
    "light", "dark", "cupcake", "bumblebee", "emerald", "corporate", "synthwave",
    "retro", "cyberpunk", "valentine", "halloween", "garden", "forest", "aqua",
    "lofi", "pastel", "fantasy", "wireframe", "black", "luxury", "dracula",
    "cmyk", "autumn", "business", "acid", "lemonade", "night", "coffee", "winter"
  ];

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', currentTheme);
    localStorage.setItem('theme', currentTheme);
  }, [currentTheme]);

  const handleLogout = () => {
    logout();
    navigate('/auth');
  };

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/notifications`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const unreadNotifications = data.filter(notification => notification.read_at === null);
        const readNotifications = data.filter(notification => notification.read_at !== null);
        setNotifications(unreadNotifications);
        setArchivedNotifications(readNotifications);
        setUnreadCount(unreadNotifications.length);
        return data;
      } else {
        console.error('Failed to fetch notifications');
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
    return [];
  };

  const markAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/notifications/${notificationId}/mark-as-read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
        },
      });

      if (response.ok) {
        setNotifications(prevNotifications =>
          prevNotifications.filter(notification => notification.id !== notificationId)
        );
        setArchivedNotifications(prevArchivedNotifications => 
          [...prevArchivedNotifications, ...notifications.filter(notification => notification.id === notificationId)]
        );
        setUnreadCount(prevCount => prevCount - 1);
      } else {
        console.error('Failed to mark notification as read');
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const updateMoodStats = (notifications) => {
    const stats = {};
    notifications.forEach(notification => {
      const message = notification.data?.message || notification.message || '';
      const mood = message.match(/Настроение: (.*?)(?=\s|$)/)?.[1];
      if (mood) {
        stats[mood] = (stats[mood] || 0) + 1;
      }
    });
    setMoodStats(stats);
  };

  useEffect(() => {
    fetchNotifications().then(allNotifications => {
      updateMoodStats(allNotifications);
    });

    echoInstance.private('notifications')
      .listen('NotificationEvent', (e) => {
        console.log('Новое уведомление:', e);

        let notificationText = '';

        if (e && e.message) {
          notificationText = e.message;
        } else if (e.notification && e.notification.data && e.notification.data.message) {
          notificationText = e.notification.data.message;
        } else if (e.notification && e.notification.message) {
          notificationText = e.notification.message;
        } else {
          console.error('Полученные данные уведомления не содержат ожидаемой структуры:', e);
          return;
        }

        setShowNotification(true);

        setTimeout(() => {
          setShowNotification(false);
        }, 3000);

        fetchNotifications().then(allNotifications => {
          updateMoodStats(allNotifications);
        });
      });

    return () => {
      echoInstance.leave('notifications');
    };
  }, []);

  const toggleView = () => {
    setIsArchiveView(!isArchiveView);
  };

  const filteredNotifications = (isArchiveView ? archivedNotifications : notifications)
    .filter(notification => {
      const message = notification.data?.message || notification.message || '';
      return message.toLowerCase().includes(searchTerm.toLowerCase()) &&
             (!selectedMood || message.includes(`Настроение: ${selectedMood}`));
    });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeNotificationModal = () => {
    document.getElementById('notification_modal').close();
  };

  return (
    <>
      {/* Mobile menu button */}
      <button className="lg:hidden fixed top-2 right-4 z-50 btn btn-circle btn-ghost" onClick={toggleSidebar}>
        {isSidebarOpen ? <FaTimes size={24} className="text-base-content" /> : <FaBars size={24} className="text-base-content" />}
      </button>

      {/* Sidebar */}
      <div className={`fixed inset-0 bg-base-100  z-40 transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 lg:w-18 lg:inset-auto lg:top-0 lg:left-0 lg:bottom-0`}>
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-center h-16 border-b border-base-300 dark:border-base-700">
            <span className="text-2xl font-bold text-primary md:hidden">NIKTA</span>
            <span className="text-2xl font-bold text-primary hidden md:block">N</span>
          </div>
          <ul className="menu menu-compact flex-grow p-4 gap-2">
            <li>
              <Link to='/main/chats' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Чаты">
                  <FaComments size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Чаты</span>
              </Link>
            </li>
            <li>
              <Link to='/main/kanban' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Канбан">
                  <FaClipboardList size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Канбан</span>
              </Link>
            </li>
            <li>
              <Link to='/main/clients' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Клиенты">
                  <FaUsers size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Клиенты</span>
              </Link>
            </li>
            <li>
              <Link to='/main/mailings' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Рассылки">
                  <IoMdMail size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Рассылки</span>
              </Link>
            </li>
            <li>
              <Link to='/main/easyforms' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="ИзиФормы">
                  <FaFileAlt size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">ИзиФормы</span>
              </Link>
            </li>
            <li>
              <Link to='/main/settings' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Настройки">
                  <FaCog size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Настройки</span>
              </Link>
            </li>
            <li>
              <Link to='/main/profile' className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700" onClick={() => setIsSidebarOpen(false)}>
                <div className="tooltip tooltip-right" data-tip="Профиль">
                  <FaUserCircle size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Профиль</span>
              </Link>
            </li>
            <li>
              <a onClick={() => {
                document.getElementById('notification_modal').showModal();
                setIsSidebarOpen(false);
              }} className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700">
                <div className="tooltip tooltip-right" data-tip="Уведомления">
                  <div className="indicator">
                    <FaBell size={20} className="text-base-content" />
                    {unreadCount > 0 && (
                      <span className="badge badge-xs badge-primary indicator-item">{unreadCount}</span>
                    )}
                  </div>
                </div>
                <span className="ml-3 lg:hidden text-base-content">Уведомления</span>
              </a>
            </li>
            <li>
              <a onClick={() => {
                document.getElementById('theme_modal').showModal();
                setIsSidebarOpen(false);
              }} className="flex items-center p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700">
                <div className="tooltip tooltip-right" data-tip="Выбор темы">
                  <FaPalette size={20} className="text-base-content" />
                </div>
                <span className="ml-3 lg:hidden text-base-content">Выбор темы</span>
              </a>
            </li>
          </ul>
          <div className="p-4">
            <button onClick={() => {
              handleLogout();
              setIsSidebarOpen(false);
            }} className="flex items-center w-full p-2 rounded-lg hover:bg-base-300 dark:hover:bg-base-700">
              <div className="tooltip tooltip-right" data-tip="Выйти">
                <FaSignOutAlt size={20} className="text-base-content" />
              </div>
              <span className="ml-3 lg:hidden text-base-content">Выйти</span>
            </button>
          </div>
        </div>
      </div>

       {/* Notification Modal */}
       <dialog id="notification_modal" className="modal">
        <div className="modal-box w-11/12 max-w-5xl bg-base-100 dark:bg-base-800 text-base-content dark:text-base-content p-0 overflow-hidden">
          <div className="bg-base-200 dark:bg-base-700 p-4 flex justify-between items-center">
            <h3 className="font-bold text-xl">Уведомления</h3>
            <button onClick={toggleView} className="btn btn-sm btn-outline">
              {isArchiveView ? "Непрочитанные" : "Архив"}
            </button>
          </div>
          
          <div className="p-4">
            <div className="flex gap-4 mb-6">
              <div className="form-control flex-grow">
                <div className="input-group w-full relative">
                  <input
                    type="text"
                    placeholder="Поиск..."
                    className="input input-bordered w-full bg-base-200 dark:bg-base-700 text-base-content dark:text-base-content pl-10"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-base-content opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </span>
                </div>
              </div>
              <select
                className="select select-bordered bg-base-200 dark:bg-base-700 text-base-content dark:text-base-content w-48"
                value={selectedMood}
                onChange={(e) => setSelectedMood(e.target.value)}
              >
                <option value="">Все настроения</option>
                {Object.keys(moodStats).map(mood => (
                  <option key={mood} value={mood}>{mood} ({moodStats[mood]})</option>
                ))}
              </select>
            </div>
            
            <div className="overflow-y-auto h-[calc(70vh-200px)] pr-2">
              {filteredNotifications.length > 0 ? (
                filteredNotifications.map(notification => (
                  <div 
                    key={notification.id}
                    className="card bg-base-200 dark:bg-base-700 shadow-lg mb-4 overflow-hidden"
                  >
                    <div className="card-body p-4">
                      <div className="flex justify-between items-start mb-2">
                        <p className="text-base-content dark:text-base-content text-lg  flex-grow">
                          {notification.data?.message || notification.message || 'Уведомление без сообщения'}
                        </p>
                        <span className="text-xs opacity-50 text-base-content dark:text-base-content whitespace-nowrap ml-2">
                          {new Date(notification.created_at).toLocaleString()}
                        </span>
                      </div>
                      <div className="flex justify-end items-center mt-4 space-x-2">
                        {!isArchiveView ? (
                          <button 
                            className="btn btn-sm btn-primary"
                            onClick={() => markAsRead(notification.id)}
                          >
                            Прочитать
                          </button>
                        ) : (
                          <div className="badge badge-primary badge-outline">
                            Прочитано
                          </div>
                        )}
                        {notification.data.channel_id && notification.data.dialog_id && (
                          <Link 
                            to={`/main/chats/${notification.data.channel_id}/${notification.data.dialog_id}`}
                            className="btn btn-sm btn-secondary"
                            onClick={closeNotificationModal}
                          >
                            Перейти в чат
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-base-content dark:text-base-content text-xl font-semibold">Нет уведомлений</p>
                </div>
              )}
            </div>
          </div>
          <div className="modal-action bg-base-200 dark:bg-base-700 p-4">
            <form method="dialog" className="w-full flex justify-end">
              <button className="btn btn-primary">Закрыть</button>
            </form>
          </div>
        </div>
      </dialog>

      {/* Theme Selection Modal */}
      <dialog id="theme_modal" className="modal ">
        <div className="modal-box ">
          <h3 className="font-bold text-lg text-base-content dark:text-base-content">Выберите тему</h3>
          <div className="grid grid-cols-3 gap-2 mt-4  ">
            {themes.map((theme) => (
              <button
                key={theme}
                className={`btn ${currentTheme === theme ? 'btn-primary' : 'btn-outline'} text-base-content `}
                onClick={() => {
                  setCurrentTheme(theme);
                  document.getElementById('theme_modal').close();
                }}
              >
                {theme}
              </button>
            ))}
          </div>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-primary">Закрыть</button>
            </form>
          </div>
        </div>
      </dialog>

      {/* New Notification Popup */}
      {showNotification && (
        <div className="toast toast-top toast-end">
          <div className="alert alert-info">
            <span>Новое уведомление!</span>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
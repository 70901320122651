import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
// Удаляем импорт ReactDOM, так как мы больше не используем Portal

const FormPreview = ({ 
  fields = [], 
  title, 
  description, 
  progressMessages = [], 
  theme, 
  submitButtonText, 
  logo, 
  progressBarType, 
  progressBarStyle,
  bubbleSpeech
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log('Fields в FormPreview:', fields);
  }, [fields]);

  const handleChange = () => {
    if (!fields || fields.length === 0) return;

    const totalFields = fields.length;
    let filledFields = 0;

    fields.forEach(field => {
      if (!field || !field.id) return;

      const inputs = document.getElementsByName(field.id.toString());
      let isFilled = false;

      if (field.type === 'checkbox') {
        isFilled = Array.from(inputs).some(input => input.checked);
      } else if (field.type === 'file') {
        isFilled = inputs[0] && inputs[0].files && inputs[0].files.length > 0;
      } else {
        isFilled = inputs[0] && inputs[0].value.trim() !== '';
      }

      if (isFilled) {
        filledFields += 1;
      }
    });

    const newProgress = Math.round((filledFields / totalFields) * 100);
    setProgress(newProgress);
  };

  const getCurrentMessage = () => {
    const currentMessage = progressMessages
      .filter(msg => msg.percentage <= progress)
      .sort((a, b) => b.percentage - a.percentage)[0];
    return currentMessage ? currentMessage.message : '';
  };

  const renderProgressBar = () => {
    switch (progressBarStyle) {
      case 'rounded':
        return (
          <motion.div 
            className="bg-primary h-2.5 rounded-full" 
            style={{ width: `${progress}%` }}
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        );
      case 'stepped':
        return (
          <div className="flex w-full">
            {[0, 25, 50, 75, 100].map((step) => (
              <motion.div 
                key={step}
                className={`h-2.5 flex-1 ${progress >= step ? 'bg-primary' : 'bg-base-300'} ${step > 0 ? 'ml-0.5' : ''}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: step / 200 }}
              />
            ))}
          </div>
        );
      case 'circular':
        return (
          <div className="relative w-20 h-20 mx-auto">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle 
                className="text-base-300" 
                strokeWidth="10"
                stroke="currentColor" 
                fill="transparent" 
                r="45" 
                cx="50" 
                cy="50" 
              />
              <motion.circle 
                className="text-primary" 
                strokeWidth="10"
                strokeLinecap="round" 
                stroke="currentColor" 
                fill="transparent" 
                r="45" 
                cx="50" 
                cy="50" 
                initial={{ strokeDasharray: '0 283' }}
                animate={{ strokeDasharray: `${progress * 2.83} 283` }}
                transition={{ duration: 0.5 }}
              />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-base-content font-bold">{progress}%</span>
            </div>
          </div>
        );
      default:
        return (
          <motion.div 
            className="bg-primary h-2.5" 
            style={{ width: `${progress}%` }}
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        );
    }
  };

  const ProgressBarComponent = () => (
    <div className="sticky top-0 bg-base-100 rounded-lg z-10 p-4 mb-4">
      <div className="w-full">
        {renderProgressBar()}
      </div>
      <p className="text-center mt-2 text-sm text-base-content font-medium">{progress}% заполнено</p>
      <AnimatePresence>
        {getCurrentMessage() && (
          <motion.p 
            className="text-center mt-2 text-sm text-base-content italic"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            {getCurrentMessage()}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );

  const BubbleSpeech = () => (
    bubbleSpeech && bubbleSpeech.photo && bubbleSpeech.message && (
      <motion.div 
        className="flex items-start mb-8 bg-base-200 p-4 rounded-lg shadow-md"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex-shrink-0 mr-4">
          <img 
            src={bubbleSpeech.photo} 
            alt="Employee" 
            className="h-16 w-16 object-cover rounded-full border-4 border-primary shadow-lg" 
          />
        </div>
        <div className="flex-grow">
          <div className="bg-primary text-primary-content p-4 rounded-lg rounded-tl-none relative">
            <p className="text-base font-medium leading-relaxed">{bubbleSpeech.message}</p>
            <div className="absolute left-0 top-0 transform -translate-x-4 -translate-y-1/2 w-0 h-0 border-8 border-primary border-l-transparent border-t-transparent"></div>
          </div>
        </div>
      </motion.div>
    )
  );

  return (
    <motion.div 
      className="bg-base-100 p-8 rounded-lg shadow-lg relative" // Добавлено 'relative'
      data-theme={theme}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Размещаем прогресс-бар внутри формы без Portal */}
      <ProgressBarComponent />

      <div className={`flex ${progressBarType === 'side' ? 'flex-row space-x-8' : 'flex-col'}`}>
        {/* Убираем дополнительный прогресс-бар */}
        <div className={`${progressBarType === 'side' ? 'w-3/4' : 'w-full'}`}>
          <div className="flex items-center mb-6">
            {logo && <img src={logo} alt="Logo" className="h-16 mr-6 object-contain" />}
            <div>
              <motion.h2 
                className="text-3xl font-bold text-base-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {title || 'Предпросмотр формы'}
              </motion.h2>
              {description && (
                <motion.p 
                  className="text-base-content mt-2 text-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  {description}
                </motion.p>
              )}
            </div>
          </div>

          <BubbleSpeech />
          
          <form onChange={handleChange} className="space-y-6">
            <AnimatePresence>
              {fields && fields.map((field) => (
                field && field.id && (
                  <motion.div 
                    key={field.id} 
                    className="bg-base-200 p-4 rounded-lg shadow-sm"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <label className="block text-base font-medium text-base-content mb-2">
                      {field.emoji && <span className="mr-2">{field.emoji}</span>}
                      {field.label}
                    </label>
                    {field.type === 'input' && (
                      <input type="text" name={field.id.toString()} className="input input-bordered w-full" />
                    )}
                    {field.type === 'textarea' && (
                      <textarea name={field.id.toString()} className="textarea textarea-bordered w-full" rows="3"></textarea>
                    )}
                    {field.type === 'radio' && (
                      <div className="space-y-2">
                        {field.options && field.options.map((option) => (
                          <div key={option.id} className="flex items-center">
                            <input type="radio" name={field.id.toString()} value={option.value} className="radio mr-2" />
                            <span className="text-base-content">{option.value}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    {field.type === 'checkbox' && (
                      <div className="space-y-2">
                        {field.options && field.options.map((option) => (
                          <div key={option.id} className="flex items-center">
                            <input type="checkbox" name={field.id.toString()} value={option.value} className="checkbox mr-2" />
                            <span className="text-base-content">{option.value}</span>
                          </div>
                        ))}
                      </div>
                    )}
                    {field.type === 'select' && (
                      <select name={field.id.toString()} className="select select-bordered w-full">
                        <option value="">Выберите опцию</option>
                        {field.options && field.options.map((option) => (
                          <option key={option.id} value={option.value}>
                            {option.value}
                          </option>
                        ))}
                      </select>
                    )}
                    {field.type === 'file' && (
                      <input type="file" name={field.id.toString()} className="file-input file-input-bordered w-full" />
                    )}
                  </motion.div>
                )
              ))}
            </AnimatePresence>
            <motion.button 
              type="submit" 
              className="btn btn-primary btn-lg w-full"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {submitButtonText || 'Отправить'}
            </motion.button>
          </form>
        </div>
        {progressBarType === 'side' && <ProgressBarComponent />}
      </div>
    </motion.div>
  );
};

export default FormPreview;
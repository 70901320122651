import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logo from './assets/logo.svg';
import mgm from '../../images/mgm.jpg';
import case1 from './case1.jpg';
import konomic from './konomic.png'; // Импортируем изображение для SIA Konomic
import konomic2 from './konomic2.jpg';

const Case = () => {
    const { caseId } = useParams();
    const navigate = useNavigate();
    const [menuActive, setMenuActive] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Данные о кейсах
    const cases = [
        {
            id: 'mgm-logistics',
            title: 'Автоматизация логистических операций для mgmlogistic',
            description: 'Оптимизация бизнес-процессов и автоматизация операций на маркетплейсах Ozon и Wildberries',
            image: mgm,
            company: 'MGM Logistic',
            fullDescription: 'Компания обратилась к нам с запросом на разработку автоматизированного решения для обработки товаров на маркетплейсах Ozon и Wildberries. Основная цель - сократить трудозатраты на выполнение операций, которые ранее требовали участия пяти сотрудников.',
            solution: [
                'Автоматическая постановка ТН ВЭД кодов для товаров',
                'Мониторинг интернет-ссылок и проверка информации о товарах',
                'Автоматическое формирование итоговых Excel-отчетов',
                'Полная интеграция с маркетплейсами Ozon и Wildberries'
            ],
            results: [
                'Сокращение расходов на зарплаты в 4 раза',
                'Ускорение и повышение точности операций',
                'Минимизация человеческих ошибок'
            ],
            screenshot: case1
        },
   
        // Добавьте дополнительные кейсы по аналогии
    ];

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
        document.body.classList.toggle('hidden_md', menuActive);
    };

    const openModal = () => {
        document.getElementById('contact_modal').showModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        const url = 'https://nikta.ai/api/contact/send';
        const message = {
            message: `Имя: ${name}\nТелефон: ${phone}\nСообщение: ${feedbackMessage}`,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(message),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccess('Мы получили вашу заявку!');
                setName('');
                setPhone('');
                setFeedbackMessage('');
            } else {
                setError('Ошибка при отправке сообщения. Попробуйте еще раз.');
            }
        } catch (err) {
            console.error(err);
            setError('Произошла ошибка при отправке. Попробуйте еще раз.');
        } finally {
            setLoading(false);
        }
    };

    // Рендер детального просмотра кейса
    const renderCaseDetail = (caseItem) => (
        <div className="case bg-gray-50 min-h-screen pt-32 p-4">
            <div className="max-w-4xl mx-auto">
                <button 
                    onClick={() => navigate('/cases')}
                    className="mb-6 flex items-center text-blue-600 hover:text-blue-800"
                >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    Назад к списку кейсов
                </button>

                <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">{caseItem.title}</h2>
                
                <div className="flex items-center mb-10">
                    <img src={caseItem.image} className='w-32 md:w-48 mr-6' alt={`${caseItem.company} logo`} />
                    <p className="text-xl text-gray-700">
                        Кейс по оптимизации бизнес-процессов для компании <a href="https://{caseItem.company === 'SIA Konomic' ? 'konomic.com' : 'mgmlogistic.ru'}" className='text-blue-600 hover:underline font-semibold' target="_blank" rel="noopener noreferrer">{caseItem.company}</a>
                    </p>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Задача</h3>
                    <p className="text-lg text-gray-700 mb-6">{caseItem.fullDescription}</p>

                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Наше решение</h3>
                    <ul className="list-none space-y-3 text-lg text-gray-700 mb-6">
                        {caseItem.solution.map((item, index) => (
                            <li key={index} className="flex items-start">
                                <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="bg-white shadow-lg rounded-lg p-8 mb-10">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Результаты</h3>
                    <p className="text-lg text-gray-700 mb-6">
                        Наше решение позволило компании <strong className="font-semibold">{caseItem.company}</strong> достичь следующих результатов:
                    </p>
                    <ul className="list-none space-y-3 text-lg text-gray-700 mb-6">
                        {caseItem.results.map((result, index) => (
                            <li key={index} className="flex items-start">
                                <svg className="w-6 h-6 text-blue-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                                {result}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-10">
                    <img src={caseItem.screenshot} alt="Интерфейс автоматизации" className="w-full h-auto" />
                    <div className="p-6">
                        <h3 className="text-2xl font-bold text-gray-800 mb-2">Скриншот разработанного решения</h3>
                        <p className="text-lg text-gray-700">Интерфейс автоматизированной системы для {caseItem.company}</p>
                    </div>
                </div>

                <div className="bg-blue-50 border-l-4 border-blue-500 p-6 rounded-lg mb-10">
                    <h3 className="text-2xl font-bold text-gray-800 mb-2">Итог</h3>
                    <p className="text-lg text-gray-700">
                        {caseItem.company === 'SIA Konomic'
                            ? `Успешная автоматизация процессов позволила компании ${caseItem.company} значительно повысить SLA до невероятного уровня - ${calculateSLAImprovement(50, 2)}% улучшения. Теперь время ответа составляет всего 2 минуты, тогда как ранее это занимало 50 минут.`
                            : `Успешная экономия времени и ресурсов, а также более точная и эффективная обработка данных. Автоматизация позволила компании ${caseItem.company} оптимизировать свои бизнес-процессы и повысить конкурентоспособность на рынке логистических услуг.`}
                    </p>
                </div>
            </div>
        </div>
    );

    // Рендер списка кейсов
    const renderCasesList = () => (
        <div className="case bg-gray-50 min-h-screen pt-32 p-4">
            <div className="max-w-6xl mx-auto">
                <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-12 text-center">
                    Наши кейсы
                </h2>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {cases.map((caseItem) => (
                        <div 
                            key={caseItem.id}
                            onClick={() => navigate(`/cases/${caseItem.id}`)}
                            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                        >
                            <div className="relative">
                                <img 
                                    src={caseItem.image} 
                                    alt={caseItem.company} 
                                    className="w-full h-48 object-cover"
                                />
                                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
                                    <h3 className="text-white text-xl font-bold">{caseItem.company}</h3>
                                </div>
                            </div>
                            <div className="p-6">
                                <h4 className="text-xl font-semibold text-gray-900 mb-3">
                                    {caseItem.title}
                                </h4>
                                <p className="text-gray-600 mb-4">
                                    {caseItem.description}
                                </p>
                                <div className="flex items-center text-blue-600">
                                    Подробнее
                                    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    // Функция расчета улучшения SLA в процентах
    const calculateSLAImprovement = (original, newSLA) => {
        const improvement = ((original - newSLA) / original) * 100;
        return Math.round(improvement);
    };

    return (
        <section className="case-container">
            {/* Хедер */}
            <header className={`header ${isScrolled ? 'shadow-lg transition' : ''}`}>
                <div className="container">
                    <div className="header__logo">
                        <a className="header__logo-link" href="/">
                            <img className="header__logo-image" src={logo} alt="logo" />
                        </a>
                    </div>
                    <nav className="header__nav">
                        <a className="header__nav-link" href="#advantages">AI ассистенты</a>
                        <a className="header__nav-link" href="#roles">Что они могут</a>
                        <a className="header__nav-link" href="#howItWork">Как это работает</a>
                        <a className="header__nav-link" href="/cases">Кейсы</a>
                    </nav>
                    <div className="header__burger">
                        <button
                            className="lg:hidden text-2xl p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors"
                            onClick={toggleMenu}
                        >
                            ☰
                        </button>
                    </div>
                    <div className="header__login">
                        <Link to="/auth" className="header__login-button">Войти</Link>
                    </div>
                </div>
            </header>

            {/* Меню */}
            <div className={`main-menu ${menuActive ? 'active' : ''}`}>
                <div className="main-menu__body" onClick={(e) => {
                    if (e.target === e.currentTarget) toggleMenu();
                }}>
                    <button className="main-menu__close" onClick={toggleMenu}></button>
                    <nav className="main-menu__nav">
                        <a className="main-menu__nav-link" href="#advantages" onClick={toggleMenu}>Почему наши сотрудники</a>
                        <a className="main-menu__nav-link" href="#roles" onClick={toggleMenu}>Что они могут</a>
                        <a className="main-menu__nav-link" href="#howItWork" onClick={toggleMenu}>Как это работает</a>
                        <a className="main-menu__nav-link" href="/nikta/cases" onClick={toggleMenu}>Кейсы</a>
                    </nav>
                    <div className="main-menu__login">
                        <Link to="/auth" className="main-menu__login-button" onClick={toggleMenu}>Войти</Link>
                    </div>
                </div>
            </div>

            {/* Основное содержимое */}
            {caseId 
                ? (cases.find(c => c.id === caseId) 
                    ? renderCaseDetail(cases.find(c => c.id === caseId)) 
                    : (
                        <div className="flex items-center justify-center min-h-screen">
                            <p className="text-xl text-red-500">Кейс не найден.</p>
                            <button 
                                onClick={() => navigate('/cases')} 
                                className="ml-4 text-blue-600 underline"
                            >
                                Вернуться к списку кейсов
                            </button>
                        </div>
                    )
                  )
                : renderCasesList()
            }

            {/* Футер */}
            <footer className="footer flex flex-col">
                <div className="container flex flex-col items-start md:items-end w-full">
                    <nav className="footer__nav text-right">
                        <a className="footer__nav-link t-20" href="#assistants">О сотрудниках</a>
                        <a className="footer__nav-link t-20" href="#advantages">Преимущества</a>
                        <a className="footer__nav-link t-20" href="#roles">Роли</a>
                        <a className="footer__nav-link t-20" href="/nikta/cases">Кейсы</a>
                    </nav>

                    <div className="footer__content flex justify-between w-full">
                        <figure className="footer__logo">
                            <img className="footer__logo-image" src={logo} alt="logo" />
                        </figure>

                        <div className="footer__connect">
                            <button className="footer__connect-button button contact" onClick={openModal}>
                                Давайте общаться
                            </button>
                        </div>
                    </div>

                    <div className="footer__details flex flex-col md:flex-row justify-between w-full mt-4">
                        <div className="footer__company-info">
                            <span className="text-white">ООО "ЗВЕНО" ИНН 5260491864</span><br />
                            <a href="mailto:nikta.ai@yandex.ru" className="text-white">nikta.ai@yandex.ru</a>
                        </div>
                        <div className="footer__links">
                            <a href="/politika.docx" download className="text-white hover:text-blue-500">Политика конфиденциальности</a><br />
                            <a href="/pers.docx" download className="text-white hover:text-blue-500">Обработка персональных данных</a>
                        </div>
                        <span className="text-white">©Nikta 2024</span>
                    </div>
                </div>
            </footer>

            {/* Модальное окно на основе DaisyUI */}
            <dialog id="contact_modal" className="modal">
                <div className="modal-box bg-white text-center flex flex-col items-center justify-center gap-6 p-6">
                    <img className="w-48 h-12 mb-4" src={logo} alt="logo" />
                    <h3 className="font-bold text-2xl mb-2">
                        Мы свяжемся с вами в течение 15 минут
                    </h3>
                    <form className="w-full" onSubmit={handleSubmit}>
                        {error && <div className="text-red-500 mb-4">{error}</div>}
                        {success && <div className="text-green-500 mb-4">{success}</div>}
                        <input
                            type="text"
                            placeholder="Как к вам обращаться"
                            className="input input-bordered w-full mb-4 bg-white border-2 border-gray-300 text-lg p-3"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <input
                            type="tel"
                            placeholder="Ваш номер телефона"
                            className="input input-bordered w-full mb-4 bg-white border-2 border-gray-300 text-lg p-3"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                        <textarea
                            placeholder="Ваше сообщение"
                            className="textarea textarea-bordered w-full mb-4 bg-white border-2 border-gray-300 text-lg p-3"
                            value={feedbackMessage}
                            onChange={(e) => setFeedbackMessage(e.target.value)}
                            required
                        />
                        <div className="flex flex-col items-center w-full gap-3">
                            <button
                                className="btn w-full h-12 bg-accent border-0 text-white text-lg hover:bg-blue-600"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Отправка...' : 'Отправить'}
                            </button>
                            <button
                                className="btn w-full h-12 bg-neutral text-gray-700 text-lg"
                                type="button"
                                onClick={() => document.getElementById('contact_modal').close()}
                            >
                                Закрыть
                            </button>
                        </div>
                    </form>
                </div>
            </dialog>
        </section>
    );
}

export default Case;
import React, { useState, useEffect } from 'react';
import { FaTelegram, FaQrcode, FaWhatsapp } from 'react-icons/fa';

const TelegramUserBotCreator = () => {
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggleQrCode = () => {
    setShowQrCode(!showQrCode);
    if (!showQrCode && !qrCode) {
      fetchQrCode();
    }
  };

  const fetchQrCode = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://nikta.ai/api/telegram/create-session');
      const data = await response.text();
      setQrCode(data);
    } catch (error) {
      console.error('Error fetching QR code:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-base-100 dark:bg-base-700 p-6 rounded-lg shadow-md">
      <div className='flex items-center mb-4'>
        <FaTelegram className="text-primary text-2xl mr-2" />
        <h2 className='text-xl font-semibold text-base-content'>Telegram Интеграция</h2>
      </div>
      <button 
        onClick={toggleQrCode}
        className="btn btn-primary mb-4"
      >
        {showQrCode ? 'Скрыть QR-код' : 'Показать QR-код'}
      </button>
      {showQrCode && (
        <div className="bg-base-100 dark:bg-base-800 p-4 rounded-lg shadow-md">
          {loading ? (
            <div className="flex justify-center items-center">
              <span className="loading loading-spinner loading-lg text-primary"></span>
            </div>
          ) : (
            <div id="qr-code" dangerouslySetInnerHTML={{ __html: qrCode }} />
          )}
        </div>
      )}
      <div className='flex items-center mt-2'>
        <FaQrcode className="text-primary text-2xl mr-2" />
        <p className="text-sm text-base-content">Отсканируйте QR-код в приложении Telegram для создания юзербота.</p>
      </div>
    </div>
  );
};

const WhatsAppUserBotCreator = () => {
  const [showQrCode, setShowQrCode] = useState(false);

  const toggleQrCode = () => {
    setShowQrCode(!showQrCode);
  };

  return (
    <div className="bg-base-100 dark:bg-base-700 p-6 rounded-lg shadow-md">
      <div className='flex items-center mb-4'>
        <FaWhatsapp className="text-primary text-2xl mr-2" />
        <h2 className='text-xl font-semibold text-base-content'>WhatsApp Интеграция</h2>
      </div>
      <button 
        onClick={toggleQrCode}
        className="btn btn-primary mb-4"
      >
        {showQrCode ? 'Скрыть QR-код' : 'Показать QR-код'}
      </button>
      {showQrCode && (
        <div className="bg-base-100 dark:bg-base-800 p-4 rounded-lg shadow-md">
          <p>QR-код для WhatsApp пока недоступен.</p>
        </div>
      )}
      <div className='flex items-center mt-2'>
        <FaQrcode className="text-primary text-2xl mr-2" />
        <p className="text-sm text-base-content">Отсканируйте QR-код в приложении WhatsApp для создания юзербота.</p>
      </div>
    </div>
  );
};

const Settings = () => {
  return (
    <div className="flex-1 p-4 overflow-y-auto">
      <div className="mx-auto">
        <h1 className='text-2xl font-bold text-base-content mb-6'>Настройки</h1>
        
        <div className='flex flex-col gap-6'>
          <TelegramUserBotCreator />
          <WhatsAppUserBotCreator />
          
          {/* Здесь можно добавить другие секции настроек */}
          
        </div>
      </div>
    </div>
  );
};

export default Settings;
import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import FormBuilder from './FormBuilder';
import FormList from './FormList';
import FormResults from './FormResults';
import { FaList, FaPlus } from 'react-icons/fa';

const EasyFormLayout = () => {
  return (
    <div className="container mx-auto p-4 h-full">
      <h1 className="text-2xl font-bold text-base-content mb-6">ИзиФормы</h1>
      
      <div className="flex justify-center mb-8 space-x-6">
        <NavLink
          to="/main/easyforms/forms"
          end
          className={({ isActive }) =>
            `btn btn-lg ${
              isActive ? 'btn-primary shadow-lg' : 'btn-ghost hover:btn-primary hover:shadow-md'
            } flex items-center gap-3 px-8 transition-all duration-200`
          }
        >
          <FaList className="text-xl text-base-content" /> <span className="text-base-content">Список форм</span>
        </NavLink>
        <NavLink
          to="/main/easyforms/forms/create"
          className={({ isActive }) =>
            `btn btn-lg ${
              isActive ? 'btn-secondary shadow-lg' : 'btn-ghost hover:btn-secondary hover:shadow-md'
            } flex items-center gap-3 px-8 transition-all duration-200`
          }
        >
          <FaPlus className="text-xl text-base-content" /> <span className="text-base-content">Создать форму</span>
        </NavLink>
      </div>

      <div className="bg-base-100 rounded-box p-6 shadow-xl h-[80%] w-full ">
        <Routes>
          <Route index element={<FormList />} />
          <Route path="forms" element={<FormList />} />
          <Route path="forms/create" element={<FormBuilder />} />
          <Route path="forms/:id/edit" element={<FormBuilder />} />
          <Route path="forms/:id/results" element={<FormResults />} />
        </Routes>
      </div>
    </div>
  );
};

export default EasyFormLayout;
import React, { useState, useEffect } from 'react';
import FormPreview from './FormPreview';
import api from '../../api';
import EmojiPicker from 'emoji-picker-react';
import { FaPlus, FaTrash, FaSave, FaUpload, FaSearch } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const themes = [
  "light", "dark", "cupcake", "bumblebee", "emerald", "corporate", "synthwave", "retro", "cyberpunk", "valentine",
  "halloween", "garden", "forest", "aqua", "lofi", "pastel", "fantasy", "wireframe", "black", "luxury", "dracula",
  "cmyk", "autumn", "business", "acid", "lemonade", "night", "coffee", "winter", "dim", "nord", "sunset"
];

const progressBarTypes = ['top'];
const progressBarStyles = ['default', 'rounded', 'stepped', 'circular'];

const FormBuilder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formFields, setFormFields] = useState([]);
  const [currentField, setCurrentField] = useState({ type: 'input', label: '', options: [], emoji: '' });
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [progressMessages, setProgressMessages] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('light');
  const [submitButtonText, setSubmitButtonText] = useState('Отправить');
  const [formLink, setFormLink] = useState('');
  const [logo, setLogo] = useState({ file: null, previewUrl: null });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [progressBarType, setProgressBarType] = useState('top');
  const [progressBarStyle, setProgressBarStyle] = useState('default');
  const [bubbleSpeech, setBubbleSpeech] = useState({ photo: null, message: '', file: null });
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [formType, setFormType] = useState('general');
  const [selectedClient, setSelectedClient] = useState(null);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchForm = async () => {
      if (id) {
        try {
          const response = await api.get(`/forms/${id}`);
          const data = response.data;
          const params = typeof data.params === 'string' ? JSON.parse(data.params) : data.params;
          let fields = typeof data.fields === 'string' ? JSON.parse(data.fields) : data.fields;

          if (typeof fields === 'string') {
            fields = JSON.parse(fields);
          }

          setFormTitle(data.name);
          setFormDescription(data.description);
          setSelectedTheme(params.theme || 'light');
          setSubmitButtonText(params.submitButtonText || 'Отправить');
          setProgressBarType(params.progressBarType || 'top');
          setProgressBarStyle(params.progressBarStyle || 'default');
          setBubbleSpeech(params.bubbleSpeech || { photo: null, message: '' });
          setProgressMessages(params.progressMessages || []);
          setFormFields(fields || []);
          
          if (params.client_id) {
            setFormType('client-specific');
            setClientId(params.client_id);
            const client = clients.find(c => c.id === params.client_id);
            if (client) {
              setSelectedClient(client);
            } else {
              try {
                const clientResponse = await api.get(`/clients/${params.client_id}`);
                setSelectedClient(clientResponse.data);
              } catch (error) {
                console.error('Ошибка при загрузке данных клиента:', error);
                toast.error('Не удалось загрузить данные клиента');
              }
            }
          } else {
            setFormType('general');
            setClientId(null);
            setSelectedClient(null);
          }

          if (data.logo) {
            const logoUrl = `https://nikta.ai${data.logo}`;
            setLogo({ file: null, previewUrl: logoUrl });
          }

          if (params.bubbleSpeech && params.bubbleSpeech.photo) {
            const bubblePhotoUrl = `https://nikta.ai${params.bubbleSpeech.photo}`;
            setBubbleSpeech(prev => ({ ...prev, photo: bubblePhotoUrl }));
          }

        } catch (error) {
          console.error('Ошибка при загрузке формы для редактирования:', error);
          toast.error('Ошибка при загрузке формы');
        }
      }
    };

    fetchForm();
  }, [id, clients]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await api.get('/clients');
        setClients(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке клиентов:', error);
        toast.error('Не удалось загрузить список клиентов');
      }
    };

    fetchClients();
  }, []);

  const addField = () => {
    if (currentField.label) {
      setFormFields([...formFields, { ...currentField, id: Date.now() }]);
      setCurrentField({ type: 'input', label: '', options: [], emoji: '' });
    }
  };

  const updateCurrentField = (e) => {
    const { name, value } = e.target;
    setCurrentField({ ...currentField, [name]: value });
  };

  const addOption = () => {
    setCurrentField({
      ...currentField,
      options: [...currentField.options, { id: Date.now(), value: '' }]
    });
  };

  const updateOption = (id, value) => {
    setCurrentField({
      ...currentField,
      options: currentField.options.map(option =>
        option.id === id ? { ...option, value } : option
      )
    });
  };

  const deleteField = (id) => {
    setFormFields(formFields.filter(field => field.id !== id));
  };

  const onEmojiClick = (emojiObject) => {
    setCurrentField({ ...currentField, emoji: emojiObject.emoji });
    setShowEmojiPicker(false);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo({
        file,
        previewUrl: URL.createObjectURL(file)
      });
    }
  };

  const deleteLogo = () => {
    setLogo({ file: null, previewUrl: null });
  };

  const addProgressMessage = () => {
    setProgressMessages([...progressMessages, { id: Date.now(), message: '', percentage: 0 }]);
  };

  const updateProgressMessage = (id, field, value) => {
    setProgressMessages(progressMessages.map(msg =>
      msg.id === id ? { ...msg, [field]: value } : msg
    ));
  };

  const deleteProgressMessage = (id) => {
    setProgressMessages(progressMessages.filter(msg => msg.id !== id));
  };

  const handleBubblePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBubbleSpeech({
        ...bubbleSpeech,
        photo: URL.createObjectURL(file),
        file
      });
    }
  };

  const updateBubbleMessage = (e) => {
    setBubbleSpeech({ ...bubbleSpeech, message: e.target.value });
  };

  const handleClientSelect = (client) => {
    setClientId(client.id);
    setSelectedClient(client);
    setFormType('client-specific');
    setIsClientModalOpen(false);
    toast.success(`Клиент "${client.name || client.login || client.email || `ID: ${client.id}`}" выбран`);
  };

  const handleFormTypeChange = (type) => {
    setFormType(type);
    if (type === 'general') {
      setClientId(null);
      setSelectedClient(null);
    }
  };

  const saveForm = async () => {
    try {
      const formData = new FormData();
      formData.append('name', formTitle);
      formData.append('description', formDescription);
      formData.append('status', 'active');
      formData.append('params', JSON.stringify({
        theme: selectedTheme,
        submitButtonText,
        progressBarType,
        progressBarStyle,
        bubbleSpeech,
        progressMessages,
        client_id: formType === 'client-specific' ? clientId : null
      }));

      const adjustedFields = formFields.map(field => {
        const adjustedType = field.type === 'text' ? 'input' : field.type;
        return { ...field, type: adjustedType };
      });

      formData.append('fields', JSON.stringify(adjustedFields));
      if (logo.file) {
        formData.append('logo', logo.file);
      }

      if (bubbleSpeech.file) {
        formData.append('bubblePhoto', bubbleSpeech.file);
      }

      let response;
      if (id) {
        response = await api.post(`/forms/${id}/update`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await api.post('/forms/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      const uniqueKey = response.data.unique_key;
      const formLink = `${window.location.origin}/forms/s/${uniqueKey}`;
      setFormLink(formLink);

      toast.success('Форма успешно сохранена');
      navigate('/forms');

    } catch (error) {
      console.error('Ошибка при сохранении формы:', error);
      toast.error('Ошибка при сохранении формы');
    }
  };

  const filteredClients = clients.filter(client => {
    const term = searchTerm.toLowerCase();
    return Object.values(client).some(value => 
      String(value).toLowerCase().includes(term)
    );
  });

  return (
    <div className="flex flex-col lg:flex-row gap-8 h-full w-full overflow-y-auto">
      <div className="w-full lg:w-1/2 p-6 rounded-lg shadow-lg bg-base-200 h-max w-full mb-4">
        <h2 className="text-2xl font-bold mb-6 text-base-content">
          {id ? 'Редактирование формы' : 'Конструктор формы'}
        </h2>

        {/* Выбор типа формы */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-4 text-base-content">Тип формы</h3>
          <div className="flex gap-4">
            <label className="label cursor-pointer">
              <input
                type="radio"
                name="formType"
                className="radio radio-primary"
                checked={formType === 'general'}
                onChange={() => handleFormTypeChange('general')}
              />
              <span className="label-text ml-2">Общая форма</span>
            </label>
            <label className="label cursor-pointer">
              <input
                type="radio"
                name="formType"
                className="radio radio-primary"
                checked={formType === 'client-specific'}
                onChange={() => handleFormTypeChange('client-specific')}
              />
              <span className="label-text ml-2">Форма для клиента</span>
            </label>
          </div>
        </div>

        {/* Выбор клиента */}
        {formType === 'client-specific' && (
          <div className="mb-6">
            {selectedClient ? (
              <div className="bg-base-300 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <h4 className="font-semibold">Выбранный клиент:</h4>
                    <p className="text-lg">
                      {selectedClient.name || selectedClient.login || selectedClient.email || `ID: ${selectedClient.id}`}
                    </p>
                    <p className="text-sm text-gray-500">
                      {selectedClient.email}
                    </p>
                  </div>
                  <button
                    className="btn btn-sm btn-outline"
                    onClick={() => setIsClientModalOpen(true)}
                  >
                    Изменить
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => setIsClientModalOpen(true)}
              >
                Выбрать клиента
              </button>
            )}
          </div>
        )}

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-4 text-base-content">Основные настройки</h3>
          <input
            type="text"
            value={formTitle}
            onChange={(e) => setFormTitle(e.target.value)}
            placeholder="Название формы"
            className="input input-bordered w-full mb-4 text-base-content"
          />
          <textarea
            value={formDescription}
            onChange={(e) => setFormDescription(e.target.value)}
            placeholder="Описание формы"
            className="textarea textarea-bordered w-full mb-4 text-base-content"
            rows="3"
          />
          <select
            value={selectedTheme}
            onChange={(e) => setSelectedTheme(e.target.value)}
            className="select select-bordered w-full mb-4 text-base-content"
          >
            {themes.map((theme) => (
              <option key={theme} value={theme}>
                {theme}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={submitButtonText}
            onChange={(e) => setSubmitButtonText(e.target.value)}
            placeholder="Текст кнопки отправки"
            className="input input-bordered w-full mb-4 text-base-content"
          />
          <select
            value={progressBarType}
            onChange={(e) => setProgressBarType(e.target.value)}
            className="select select-bordered w-full mb-4 text-base-content"
          >
            {progressBarTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <select
            value={progressBarStyle}
            onChange={(e) => setProgressBarStyle(e.target.value)}
            className="select select-bordered w-full mb-4 text-base-content"
          >
            {progressBarStyles.map((style) => (
              <option key={style} value={style}>
                {style}
              </option>
            ))}
          </select>
          <div className="flex items-center gap-4">
            <input 
              type="file" 
              onChange={handleLogoUpload} 
              className="file-input file-input-bordered w-full text-base-content" 
              id="logo-upload"
            />
            <label htmlFor="logo-upload" className="btn btn-outline">
              <FaUpload className="mr-2" /> Логотип
            </label>
          </div>
          
          {logo.previewUrl && (
            <div className="flex items-center gap-4">
              <img src={logo.previewUrl} alt="Logo" className="h-12 object-contain" />
              <button onClick={deleteLogo} className="btn btn-error btn-sm">
                <FaTrash className="mr-2" /> Удалить логотип
              </button>
            </div>
          )}
        </div>

        <div className="divider"></div>

        <h3 className="text-xl font-semibold mb-4 text-base-content">Добавление полей</h3>
        
        <div className="space-y-4 mb-6">
          <select
            name="type"
            value={currentField.type}
            onChange={updateCurrentField}
            className="select select-bordered w-full text-base-content"
          >
            <option value="input">Текстовое поле</option>
            <option value="textarea">Многострочное текстовое поле</option>
            <option value="radio">Радио кнопки</option>
            <option value="checkbox">Чекбоксы</option>
            <option value="select">Выпадающий список</option>
            <option value="file">Загрузка файла</option>
          </select>
          
          <div className="flex gap-4">
            <input
              type="text"
              name="label"
              value={currentField.label}
              onChange={updateCurrentField}
              placeholder="Название поля"
              className="input input-bordered w-full text-base-content"
            />
            <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="btn btn-outline">
              {currentField.emoji || 'Эмодзи'}
            </button>
          </div>
          
          {showEmojiPicker && (
            <div className="mt-2">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
          
          {['radio', 'checkbox', 'select'].includes(currentField.type) && (
            <div className="space-y-2">
              {currentField.options.map((option, index) => (
                <div key={option.id} className="flex gap-2">
                  <input
                    type="text"
                    value={option.value}
                    onChange={(e) => updateOption(option.id, e.target.value)}
                    placeholder={`Вариант ${index + 1}`}
                    className="input input-bordered w-full text-base-content"
                  />
                  <button
                    onClick={() => setCurrentField({
                      ...currentField,
                      options: currentField.options.filter(o => o.id !== option.id)
                    })}
                    className="btn btn-error btn-sm"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
              <button onClick={addOption} className="btn btn-outline btn-sm">
                <FaPlus className="mr-2" /> Добавить вариант
              </button>
            </div>
          )}
          
          <button onClick={addField} className="btn btn-primary w-full">
            <FaPlus className="mr-2" /> Добавить поле
          </button>
        </div>

        <div className="divider"></div>

        <h3 className="text-xl font-semibold mb-4 text-base-content">Сообщения прогресса</h3>
        
        <div className="space-y-4 mb-6">
          {progressMessages.map((msg, index) => (
            <div key={msg.id} className="flex gap-2">
              <input
                type="text"
                value={msg.message}
                onChange={(e) => updateProgressMessage(msg.id, 'message', e.target.value)}
                placeholder={`Сообщение ${index + 1}`}
                className="input input-bordered w-full text-base-content"
              />
              <input
                type="number"
                value={msg.percentage}
                onChange={(e) => updateProgressMessage(msg.id, 'percentage', parseInt(e.target.value))}
                placeholder="%"
                className="input input-bordered w-20 text-base-content"
                min="0"
                max="100"
              />
              <button
                onClick={() => deleteProgressMessage(msg.id)}
                className="btn btn-error btn-sm"
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button onClick={addProgressMessage} className="btn btn-outline btn-sm">
            <FaPlus className="mr-2" /> Добавить сообщение
          </button>
        </div>

        <div className="divider"></div>

        <h3 className="text-xl font-semibold mb-4 text-base-content">Bubble Speech</h3>
        
        <div className="space-y-4 mb-6">
          <div className="flex items-center gap-4">
            <input 
              type="file" 
              onChange={handleBubblePhotoUpload} 
              className="file-input file-input-bordered w-full text-base-content" 
              id="bubble-photo-upload"
            />
            <label htmlFor="bubble-photo-upload" className="btn btn-outline">
              <FaUpload className="mr-2" /> Фото сотрудника
            </label>
          </div>
          
          {bubbleSpeech.photo && (
            <div className="flex items-center gap-4">
              <img src={bubbleSpeech.photo} alt="Employee" className="h-12 w-12 object-cover rounded-full" />
              <button onClick={() => setBubbleSpeech({ ...bubbleSpeech, photo: null, file: null })} className="btn btn-error btn-sm">
                <FaTrash className="mr-2" /> Удалить фото
              </button>
            </div>
          )}
          
          <textarea
            value={bubbleSpeech.message}
            onChange={updateBubbleMessage}
            placeholder="Сообщение сотрудника"
            className="textarea textarea-bordered w-full text-base-content"
            rows="3"
          />
        </div>
        
        <div className="divider"></div>
        
        <h3 className="text-xl font-semibold mb-4 text-base-content">Текущие поля формы</h3>
        
        <div className="space-y-2 mb-6">
          {formFields.map((field) => (
            <div key={field.id} className="flex items-center justify-between bg-base-300 p-2 rounded">
              <span className="text-base-content">{field.emoji} {field.label} ({field.type})</span>
              <button onClick={() => deleteField(field.id)} className="btn btn-error btn-sm">
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
        
        <div className="mt-6">
          <button onClick={saveForm} className="btn btn-success w-full">
            <FaSave className="mr-2" /> {id ? 'Сохранить изменения' : 'Создать форму'}
          </button>
        </div>
        
        {formLink && (
          <div className="mt-4">
            <p className="text-base-content mb-2">Ссылка на форму:</p>
            <input type="text" value={formLink} readOnly className="input input-bordered w-full text-base-content" />
          </div>
        )}
      </div>

      <div className="w-full lg:w-1/2 p-6 bg-base-200 rounded-lg shadow-lg h-max mb-4">
        <h2 className="text-2xl font-bold mb-6 text-base-content">Предпросмотр формы</h2>
        <FormPreview 
          fields={formFields} 
          title={formTitle}
          description={formDescription}
          progressMessages={progressMessages}
          theme={selectedTheme}
          submitButtonText={submitButtonText}
          logo={logo.previewUrl}
          progressBarType={progressBarType}
          progressBarStyle={progressBarStyle}
          bubbleSpeech={bubbleSpeech}
        />
      </div>

      {/* Модальное окно выбора клиента */}
      {isClientModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box w-11/12 max-w-5xl h-[80vh] flex flex-col">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-bold">Выбрать клиента</h3>
              <button 
                className="btn btn-sm btn-circle btn-ghost"
                onClick={() => setIsClientModalOpen(false)}
              >
                ✕
              </button>
            </div>
            
            <div className="form-control mb-6">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Поиск по имени, email, телефону..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="input input-bordered w-full"
                />
              </div>
            </div>
            
            <div className="overflow-x-auto flex-grow mb-6">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th className="w-24">Действия</th>
                    {clients.length > 0 && Object.keys(clients[0])
                      .filter(key => !['created_at', 'updated_at', 'deleted_at'].includes(key))
                      .map(key => (
                        <th key={key} className="capitalize">
                          {key.replace(/_/g, ' ')}
                        </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredClients
                    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
                    .map(client => (
                      <tr key={client.id} className="hover">
                        <td className="w-24">
                          <button
                            onClick={() => handleClientSelect(client)}
                            className="btn btn-sm btn-success gap-2"
                          >
                            Выбрать
                          </button>
                        </td>
                        {Object.entries(client)
                          .filter(([key]) => !['created_at', 'updated_at', 'deleted_at'].includes(key))
                          .map(([key, value]) => (
                            <td key={key} className="whitespace-normal">
                              {value || '-'}
                            </td>
                        ))}
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Пагинация */}
            <div className="flex justify-between items-center mt-4 mb-2">
              <span className="text-sm text-gray-500">
                Найдено клиентов: {filteredClients.length}
              </span>
              <div className="join">
                <button
                  className="join-item btn btn-sm"
                  onClick={() => setPage(Math.max(0, page - 1))}
                  disabled={page === 0}
                >
                  «
                </button>
                {Array.from({ length: Math.ceil(filteredClients.length / itemsPerPage) }, (_, i) => (
                  <button
                    key={i}
                    className={`join-item btn btn-sm ${page === i ? 'btn-active' : ''}`}
                    onClick={() => setPage(i)}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  className="join-item btn btn-sm"
                  onClick={() => setPage(Math.min(Math.ceil(filteredClients.length / itemsPerPage) - 1, page + 1))}
                  disabled={page >= Math.ceil(filteredClients.length / itemsPerPage) - 1}
                >
                  »
                </button>
              </div>
            </div>

            <div className="modal-action">
              <button
                className="btn btn-outline"
                onClick={() => setIsClientModalOpen(false)}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default FormBuilder;
import React from 'react';
import Header from './Header';
import ChatApp from './Chat/ChatApp';

function Chat() {
  return (
    <div className="flex bg-base-100 h-svh flex overflow-hidden bg-base-600">

      <main className="flex-1 flex flex-col h-screen w-full  bg-base-300">
        <div className="flex-grow flex items-center justify-center  md:p-0 ">
          <div className="w-full h-screen ">
            <ChatApp />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Chat;
import React from 'react';
import Header from './Header';

function Layout({ children }) {
    return (
        <div className="flex bg-base-300 dark:bg-base-900 h-svh ">
            <Header />
            <main className="flex-1 p-4 overflow-hidden flex flex-col md:ml-[5%] h-[100%] md:h-[100%]">
                {children}
            </main>
        </div>
    );
}

export default Layout;
import React, { useState, useEffect } from 'react';
import api from '../../api';
import { Link } from 'react-router-dom';
import { FaEdit, FaExternalLinkAlt, FaCopy, FaTrash, FaChartBar } from 'react-icons/fa';

const FormList = () => {
  const [forms, setForms] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClient, setSelectedClient] = useState('all');
  const [showToast, setShowToast] = useState(false);

  

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await api.get('/clients'); // Предполагается, что эндпоинт /clients возвращает список клиентов
        setClients(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке клиентов:', error);
        // Обработка ошибки, если необходимо
      }
    };

    const fetchForms = async () => {
      try {
        const response = await api.get('/forms');
        setForms(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке списка форм:', error);
        setError('Не удалось загрузить формы. Пожалуйста, попробуйте позже.');
        setLoading(false);
      }
    };

    fetchClients();
    fetchForms();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000); // Скрыть через 3 секунды
    }, (err) => {
      console.error('Не удалось скопировать текст: ', err);
    });
  };

 

  const filteredForms = selectedClient === 'all'
    ? forms
    : forms.filter(form => form.client_id === selectedClient);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return <div className="alert alert-error shadow-lg text-base-content">{error}</div>;
  }

  return (
    <div className="text-base-content">
      <h2 className="text-2xl font-bold mb-4">Список форм</h2>

      

      {filteredForms.length === 0 ? (
        <div className="alert alert-info shadow-lg">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>У вас пока нет созданных форм. Создайте свою первую форму!</span>
          </div>
        </div>
      ) : (
        <div className="overflow-x-auto overflow-y-auto h-[60vh]">
          <table className="table w-full">
            <thead>
              <tr>
                <th className="text-base-content">Логотип</th>
                <th className="text-base-content">Название</th>
                <th className="text-base-content">Описание</th>
                <th className="text-base-content">ID клиента</th>
                <th className="text-base-content">Действия</th>
              </tr>
            </thead>
            <tbody>
              {filteredForms.map((form) => (
                <tr key={form.id} className="hover">
                  <td>
                    {form.logo ? (
                      <img 
                        src={`https://nikta.ai${form.logo}`} 
                        alt="Logo" 
                        className="w-10 h-10 object-contain rounded"
                      />
                    ) : (
                      <span className="text-opacity-50">-</span>
                    )}
                  </td>
                  <td className="font-medium text-base-content">{form.name}</td>
                  <td className="text-base-content">{form.description}</td>
                  <td className="text-base-content">
                    {form.client_id ? (
                      clients.find(client => client.id === form.client_id)?.name || form.client_id
                    ) : (
                      <span className="text-opacity-50">Общая</span>
                    )}
                  </td>
                  <td className="flex space-x-2">
                    <Link 
                      to={`/main/easyforms/forms/${form.id}/edit`} 
                      className="btn btn-sm btn-info"
                      title="Редактировать"
                    >
                      <FaEdit />
                    </Link>
                    <Link
                      to={`/main/easyforms/forms/${form.id}/results`}
                      className="btn btn-sm btn-primary"
                      title="Результаты"
                    >
                      <FaChartBar />
                    </Link>
                    <a
                      href={`/forms/s/${form.unique_key}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-success"
                      title="Открыть форму"
                    >
                      <FaExternalLinkAlt />
                    </a>
                    <button
                      onClick={() => copyToClipboard(`${window.location.origin}/forms/s/${form.unique_key}`)}
                      className="btn btn-sm btn-warning"
                      title="Копировать ссылку"
                    >
                      <FaCopy />
                    </button>
                   
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showToast && (
        <div className="toast toast-top toast-end">
          <div className="alert alert-success">
            <span>Ссылка скопирована в буфер обмена!</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormList;
import React from 'react';

function Main() {
    return (
        <div className="flex-1 flex flex-col overflow-hidden">
            <div className="flex-1 overflow-auto p-4">
                <div className="max-w-4xl mx-auto">
                    {/* Alert */}
                    <div role="alert" className="alert alert-info mb-4 flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <span>Мы в тестировании. Возможны баги</span>
                    </div>

                    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-12rem)]">
                        {/* Анимация робота и надпись */}
                        <div className="flex flex-col items-center">
                            <div className="w-32 h-60 relative overflow-hidden">
                                <img 
                                    src="https://cdn-icons-png.flaticon.com/512/1998/1998614.png" 
                                    alt="Robot" 
                                    className="w-full h-full object-contain animate-bounce"
                                />
                            </div>
                            <h1 className="text-2xl font-bold text-center text-base-content">
                                Добро пожаловать в NIKTA AI
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
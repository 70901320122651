import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Landing from './Components/Landing/nikta-landing/Landing';
import Main from './Components/Main';
import Auth from './Components/Auth';
import { AuthProvider } from './Components/AuthContext';
import { useContext } from 'react';
import AuthContext from './Components/AuthContext';
import Profile from './Components/Profile';
import Chat from './Components/Chat';
import Clients from './Components/Clients';
import Settings from './Components/Settings';
import Kanban from './Components/Kanban';
import Mailings from './Components/Mailings';
import Case from './Components/Landing/nikta-landing/Case';
import Layout from './Components/Layout';
import EasyFormLayout from './Components/EasyForms/EasyFormLayout';
import FormDisplay from './Components/EasyForms/FormDisplay';
import FormList from './Components/EasyForms/FormList';

function PrivateRoute({ element }) {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <span className="loading loading-spinner text-secondary"></span>;
  }

  console.log('isAuthenticated:', isAuthenticated);
  return isAuthenticated ? <Layout>{element}</Layout> : <Navigate to="/auth" />;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/cases" element={<Case />} />
          <Route path="/cases/:caseId" element={<Case />} />
          <Route path="/auth" element={<Auth />} />

          <Route path="/main" element={<PrivateRoute element={<Main />} />} />
          <Route path="/main/profile" element={<PrivateRoute element={<Profile />} />} />
          <Route path="/main/chats/:channelId?/:dialogId?" element={<PrivateRoute element={<Chat />} />} />
          <Route path="/main/clients" element={<PrivateRoute element={<Clients />} />} />
          <Route path="/main/settings" element={<PrivateRoute element={<Settings />} />} />
          <Route path="/main/kanban" element={<PrivateRoute element={<Kanban />} />} />
          <Route path="/main/mailings" element={<PrivateRoute element={<Mailings />} />} />
          <Route path="/main/mailings/create" element={<PrivateRoute element={<Mailings />} />} />
          <Route path="/main/mailings/:id" element={<PrivateRoute element={<Mailings />} />} />
          <Route path="/main/easyforms/*" element={<PrivateRoute element={<EasyFormLayout />} />} />

          <Route path="/forms/s/:uniqueKey" element={<FormDisplay />} />
          <Route path="/forms" element={<PrivateRoute element={<FormList />} />} />
          <Route path="/forms/:id/edit" element={<PrivateRoute element={<EasyFormLayout />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import logo from './images/logo.svg';


function Auth() {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Добавлено состояние загрузки
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Устанавливаем состояние загрузки в true
    try {
      const success = await login(email, password, rememberMe);
      if (success) {
        navigate('/main'); // Перенаправление на /main при успешной авторизации
      } else {
        setError('Ошибка! Неверные данные'); // Установка ошибки при неуспешной авторизации
      }
    } catch (err) {
      setError('Произошла ошибка. Попробуйте снова.');
    } finally {
      setIsLoading(false); // Возвращаем состояние загрузки в false
    }
  };
  

  return (
    <div className="flex flex-col gap-2 items-center h-dvh bg-gray-100">
      <div className="hero bg-gray-100 min-h-screen w-[100%]">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="card bg-white w-full w-[100%] shadow-2xl">
            <img src={logo} className='p-10 pb-2'/>
            <form className="card-body flex flex-col gap-4 w-[90%]" onSubmit={handleSubmit}>
              <div className="form-control w-full">
                <input 
                  type="email" 
                  placeholder="почта" 
                  className="input input-bordered bg-gray-100 w-[40vh]" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-control w-full">
                <input 
                  type="password" 
                  placeholder="пароль" 
                  className="input input-bordered bg-gray-100 w-[40vh]" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
                <label className="label">
                  <a href="#" className="label-text-alt link link-hover">Забыли пароль?</a>
                </label>
              </div>
              <div className="form-control flex flex-row">
                <label className="cursor-pointer label">
                  <input 
                    type="checkbox" 
                    className="checkbox checkbox-accent " 
                    checked={rememberMe} 
                    onChange={(e) => setRememberMe(e.target.checked)} 
                  />
                  <span className="label-text ml-3 ">Запомнить</span>
                </label>
              </div>
              {error && (
                <div role="alert" className="alert alert-error w-[40vh] flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 shrink-0 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>{error}</span>
                </div>
              )}
              <div className="form-control mt-1 w-[100%] ">
                <button className="btn btn-accent w-[40vh] border-0 text-white" disabled={isLoading}>
                  {isLoading ? (
                    <span className="loading loading-spinner text-secondary"></span>
                  ) : (
                    'Войти'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const echoInstance = new Echo({
  broadcaster: 'reverb',
  key: process.env.REACT_APP_REVERB_APP_KEY,
  wsHost: process.env.REACT_APP_REVERB_HOST,
  wsPort: process.env.REACT_APP_REVERB_PORT ? parseInt(process.env.REACT_APP_REVERB_PORT) : 80,
  wssPort: process.env.REACT_APP_REVERB_PORT ? parseInt(process.env.REACT_APP_REVERB_PORT) : 443,
  forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
  authEndpoint: process.env.REACT_APP_REVERB_AUTH_ENDPOINT,
  auth: {
    headers: {
      Authorization: process.env.REACT_APP_BEARER_TOKEN,
    }
  },
});

export default echoInstance;
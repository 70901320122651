const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_AUTH = `${API_BASE_URL}/auth`;
const API_PROFILE = `${API_BASE_URL}/profile`;
const API_CHANNELS = `${API_BASE_URL}/channels`;
const API_DIALOGS = `${API_BASE_URL}/dialogs`;
const API_CLIENTS = `${API_BASE_URL}/clients`;
const API_MAILINGS = `${API_BASE_URL}/mailings`;

export default {
  API_AUTH,
  API_PROFILE,
  API_CHANNELS,
  API_DIALOGS,
  API_CLIENTS,
  API_MAILINGS,
  API_BASE_URL
};

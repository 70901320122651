import React from 'react';
import ClientsTable from "./ClientsTable";

function Clients() {
    return (
        <>
            <h1 className='text-2xl font-bold mb-4 text-base-content'>Клиенты</h1>
            <div className="flex-1 overflow-hidden flex flex-col bg-base-100 dark:bg-base-800 rounded-box shadow-lg">
                <div className="flex-1 overflow-auto p-4">
                    <ClientsTable />
                </div>
            </div>
        </>
    );
}

export default Clients;
import React, { useEffect, useState } from 'react';
import { FaUsers, FaExclamationTriangle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Kanban() {
    const [salesStages, setSalesStages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [scenarios, setScenarios] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState('');
    const [currentStageIndex, setCurrentStageIndex] = useState(0);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetchScenarios();
    }, []);

    useEffect(() => {
        if (selectedScenario) {
            fetchSalesStages(selectedScenario);
        }
    }, [selectedScenario]);

    const fetchScenarios = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/scenarios`);
            setScenarios(response.data);
            if (response.data.length > 0) {
                setSelectedScenario(response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching scenarios:', error);
            setError(error);
        }
    };

    const fetchSalesStages = async (scenarioName) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/kanban/${scenarioName}`);
            const stages = response.data.map((stage, index) => ({
                id: index + 1,
                name: stage.stage,
                clients: stage.clients.map(client => ({
                    id: client.dialog_id,
                    id2: client.channel_id,
                    name: `${client.first_name} ${client.last_name}`.trim() || client.login
                }))
            }));
            setSalesStages(stages);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const nextStage = () => {
        setCurrentStageIndex((prevIndex) => 
            prevIndex < salesStages.length - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const prevStage = () => {
        setCurrentStageIndex((prevIndex) => 
            prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
    };

    if (error) {
        return (
            <div className="flex-1 p-4 md:p-6 overflow-hidden">
                <div className="flex items-center justify-center h-full">
                    <div className="text-xl font-semibold text-error">
                        <FaExclamationTriangle className="inline mr-2" />
                        Ошибка: {error.message}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex-1 p-4 md:p-6 overflow-hidden flex flex-col h-full bg-base-100">
            <h1 className='text-xl md:text-2xl font-semibold text-base-content mb-4 md:mb-6'>Канбан доска</h1>
            <select 
                className="select select-bordered w-full max-w-xs mb-4 md:mb-6 text-base-content"
                value={selectedScenario}
                onChange={(e) => setSelectedScenario(e.target.value)}
            >
                {scenarios.map((scenario, index) => (
                    <option key={index} value={scenario}>{scenario}</option>
                ))}
            </select>
            {loading ? (
                <div className="flex-1 flex justify-center items-center">
                    <span className="loading loading-spinner text-primary"></span>
                </div>
            ) : (
                <>
                    <div className="md:hidden flex justify-between items-center mb-4">
                        <button onClick={prevStage} className="btn btn-circle btn-sm" disabled={currentStageIndex === 0}>
                            <FaChevronLeft />
                        </button>
                        <span className="text-base-content">
                            {salesStages[currentStageIndex]?.name} ({currentStageIndex + 1}/{salesStages.length})
                        </span>
                        <button onClick={nextStage} className="btn btn-circle btn-sm" disabled={currentStageIndex === salesStages.length - 1}>
                            <FaChevronRight />
                        </button>
                    </div>
                    <div className='flex-1 flex md:gap-6 overflow-x-auto pb-6'>
                        {salesStages.map((stage, index) => (
                            <div 
                                key={stage.id} 
                                className={`flex-shrink-0 w-full md:w-72 bg-base-200 rounded-lg shadow-sm ${index !== currentStageIndex ? 'hidden md:flex' : 'flex'} flex-col`}
                            >
                                <div className="p-4 border-b border-base-300">
                                    <h2 className="text-lg font-medium text-base-content mb-2">{stage.name}</h2>
                                    <div className='flex items-center text-sm text-base-content/70'>
                                        <FaUsers className="mr-2" />
                                        <span>{stage.clients.length} клиентов</span>
                                    </div>
                                </div>
                                <ul className='flex-1 p-4 space-y-2 overflow-y-auto'>
                                    {stage.clients.map((client) => (
                                        <li key={client.id} className='bg-base-100 rounded-md shadow-sm transition-all hover:shadow'>
                                            <Link 
                                                to={`/main/chats/${client.id2}/${client.id}`} 
                                                className='block p-3 text-sm text-base-content hover:bg-base-200'
                                            >
                                                {client.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default Kanban;
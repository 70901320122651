import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import FormPreview from './FormPreview';
import { motion } from 'framer-motion';

const BASE_URL = 'https://nikta.ai';

const FormDisplay = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uniqueKey } = useParams();

  const parseJSON = useCallback((data, fallback) => {
    try {
      return typeof data === 'string' ? JSON.parse(data) : data;
    } catch (e) {
      console.error('Ошибка при парсинге JSON:', e);
      return fallback;
    }
  }, []);

  const processImageUrl = useCallback((url) => {
    return url && typeof url === 'string' && url.startsWith('/') 
      ? `${BASE_URL}${url}` 
      : url;
  }, []);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const { data } = await api.get(`/forms/s/${uniqueKey}`);
        console.log('Полученные данные:', data);

        const params = parseJSON(data.params, {});
        let fields = parseJSON(data.fields, []);
        fields = Array.isArray(fields) ? fields : parseJSON(fields, []);

        const formDataProcessed = {
          fields,
          title: data.name,
          description: data.description,
          progressMessages: params.progressMessages || [],
          theme: params.theme || 'light',
          submitButtonText: params.submitButtonText || 'Отправить',
          logo: processImageUrl(data.logo),
          progressBarType: params.progressBarType || 'top',
          progressBarStyle: params.progressBarStyle || 'default',
          bubbleSpeech: {
            ...params.bubbleSpeech,
            photo: processImageUrl(params.bubbleSpeech?.photo),
          },
        };

        setFormData(formDataProcessed);
      } catch (error) {
        console.error('Ошибка при загрузке формы:', error);
        setError('Не удалось загрузить форму. Пожалуйста, попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [uniqueKey, parseJSON, processImageUrl]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-base-100">
        <div className="text-center">
          <div className="loading loading-spinner loading-lg text-primary"></div>
          <p className="mt-4 text-lg font-medium text-base-content">Загрузка формы...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-base-200">
        <div className="bg-error text-error-content p-6 rounded-lg shadow-lg max-w-md">
          <h2 className="text-2xl font-bold mb-4">Ошибка</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div data-theme={formData.theme} className="min-h-screen bg-base-300 p-4">
      <motion.div 
        className="flex justify-center items-center min-h-screen w-full h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-4xl">
          <FormPreview {...formData} />
        </div>
      </motion.div>
    </div>
  );
};

export default FormDisplay;
import React, { useState, useEffect, useRef } from 'react';
import api from './api'; 
import Sidebar from './Sidebar';
import ChatList from './Chatlist';
import ChatWindow from './ChatWindow';
import echoInstance from '../EchoGlobal';
import { useParams, useNavigate } from 'react-router-dom';

const ChatApp = () => {
  const [channels, setChannels] = useState([]);
  const [dialogs, setDialogs] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [loadingDialogs, setLoadingDialogs] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [scenarioStatus, setScenarioStatus] = useState(null);
  const selectedDialogRef = useRef(selectedDialog);
  const [unreadDialogs, setUnreadDialogs] = useState(new Set());
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const { channelId, dialogId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    selectedDialogRef.current = selectedDialog;
  }, [selectedDialog]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await api.getChannels();
        const channelsWithBotIntegration = response.data.map(channel => ({
          ...channel,
          bot_integration_id: channel.bot_integration_id || channel.id
        }));
        setChannels(channelsWithBotIntegration);
        setLoadingChannels(false);
      } catch (error) {
        console.error('Ошибка при получении каналов:', error);
        setLoadingChannels(false);
      }
    };

    fetchChannels();
  }, []);

  useEffect(() => {
    if (channels.length > 0 && channelId) {
      const channel = channels.find(c => c.id === parseInt(channelId));
      if (channel) {
        handleSelectChannel(channel);
      }
    }
  }, [channels, channelId]);

  useEffect(() => {
    if (selectedChannel && dialogs.length > 0 && dialogId) {
      const dialogToSelect = dialogs.find(d => d.id === parseInt(dialogId));
      if (dialogToSelect) {
        handleSelectDialog(dialogToSelect);
      }
    }
  }, [selectedChannel, dialogs, dialogId]);

  const handleSelectChannel = async (channel) => {
    if (!channel) return;
    if (selectedChannel && selectedChannel.id === channel.id) return;

    const updatedChannel = {
      ...channel,
      bot_integration_id: channel.bot_integration_id || channel.id
    };

    setSelectedChannel(updatedChannel);
    setSelectedDialog(null);
    setMessages([]);
    setLoadingDialogs(true);

    try {
      const response = await api.getDialogs(channel.id);
      const dialogsData = response.data;

      const dialogsWithLastMessage = await Promise.all(dialogsData.map(async (dialog) => {
        const messagesResponse = await api.getMessages(channel.id, dialog.id);
        const lastMessage = messagesResponse.data.length > 0 ? messagesResponse.data[messagesResponse.data.length - 1] : null;

        return {
          ...dialog,
          lastMessage: lastMessage 
            ? (lastMessage.file 
                ? 'Файл' 
                : lastMessage.message || 'Нет сообщений')
            : 'Нет сообщений',
          lastMessageTime: lastMessage ? lastMessage.created_at : null,
          isNew: false,
        };
      }));

      // Сортировка диалогов по времени последнего сообщения
      const sortedDialogs = dialogsWithLastMessage.sort((a, b) => {
        const timeA = new Date(a.lastMessageTime || 0).getTime();
        const timeB = new Date(b.lastMessageTime || 0).getTime();
        return timeB - timeA;
      });

      setDialogs(sortedDialogs);
      setLoadingDialogs(false);

      if (dialogId) {
        const dialogToSelect = sortedDialogs.find(d => d.id === parseInt(dialogId));
        if (dialogToSelect) {
          await handleSelectDialog(dialogToSelect);
        }
      } else {
        navigate(`/main/chats/${channel.id}`);
      }
    } catch (error) {
      console.error('Ошибка при получении диалогов:', error);
      setLoadingDialogs(false);
    }
  };


  const handleSelectDialog = async (dialog) => {
    if (!dialog || !selectedChannel || (selectedDialog && dialog.id === selectedDialog.id)) return;
  
    // Добавляем переменную для отслеживания текущего выбранного диалога
    const currentSelectedDialogId = dialog.id;
  
    setSelectedDialog(dialog);
    setLoadingMessages(true);
  
    try {
      const [messagesResponse, dialogInfoResponse] = await Promise.all([
        api.getMessages(selectedChannel.id, dialog.id),
        api.getDialogInfo(selectedChannel.id, dialog.id)
      ]);
  
      // Проверяем, не изменился ли выбранный диалог во время загрузки
      if (currentSelectedDialogId !== selectedDialogRef.current?.id) {
        console.log('Выбранный диалог изменился, прерываем обновление');
        return;
      }
  
      setMessages(messagesResponse.data);
      setScenarioStatus(dialogInfoResponse.data.need_bot_answer === true);
      setLoadingMessages(false);
      navigate(`/main/chats/${selectedChannel.id}/${dialog.id}`);
  
      // Отмечаем сообщения как просмотренные
      await api.markMessagesAsViewed(selectedChannel.id, dialog.id);
      setUnreadDialogs(prev => {
        const newSet = new Set(prev);
        newSet.delete(dialog.id);
        return newSet;
      });
    } catch (error) {
      console.error('Ошибка при загрузке сообщений:', error);
      setLoadingMessages(false);
    }
  };

  const handleSendMessage = async (messageText, file = null) => {
    if (!selectedChannel || !selectedDialog) {
      console.error('Selected channel or dialog is undefined');
      return;
    }
  
    try {
      let textMessage, fileMessage;
  
      // Отправляем текстовое сообщение, если оно есть
      if (messageText.trim()) {
        const textResponse = await api.sendMessage(selectedChannel.id, selectedDialog.id, messageText);
        textMessage = textResponse.data;
        
        if (textMessage) {
          setMessages(prevMessages => [...prevMessages, textMessage]);
          updateDialogWithLastMessage(textMessage);
        }
      }
  
      // Отправляем файл, если он есть
      if (file) {
        const fileResponse = await api.sendMessage(selectedChannel.id, selectedDialog.id, '', file);
        fileMessage = fileResponse.data;
        
        if (fileMessage) {
          setMessages(prevMessages => [...prevMessages, fileMessage]);
          updateDialogWithLastMessage(fileMessage);
        }
      }
  
      return { textMessage, fileMessage };
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      throw error;
    }
  };
  
  const updateDialogWithLastMessage = (message) => {
    setDialogs(prevDialogs => 
      prevDialogs.map(dialog => 
        dialog.id === selectedDialog.id 
          ? {
              ...dialog,
              lastMessage: message.file ? 'Файл' : message.message,
              lastMessageTime: message.created_at
            }
          : dialog
      )
    );
  };
  const handleBackToChannels = () => {
    setSelectedChannel(null);
    setSelectedDialog(null);
    setDialogs([]);
    navigate('/main/chats');
  };

  const handleBackToDialogs = () => {
    setSelectedDialog(null);
    if (selectedChannel) {
      navigate(`/main/chats/${selectedChannel.id}`);
    } else {
      navigate('/main/chats');
    }
  };

  const handleScenarioToggle = async (newStatus) => {
    if (!selectedChannel || !selectedDialog) return;

    try {
      const response = await api.updateScenarioStatus(selectedChannel.id, selectedDialog.id, newStatus ? 1 : 0);
      if (response.data && 'need_bot_answer' in response.data) {
        setScenarioStatus(response.data.need_bot_answer === 1);
      } else {
        console.error('Некорректный ответ сервера при обновлении статуса:', response.data);
      }
    } catch (error) {
      console.error('Ошибка при обновлении статуса сценария:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(prev => !prev);
  };

  useEffect(() => {
    const subscribeToChatEvents = () => {
      echoInstance.private('chats')
        .listen('ChatEvent', (e) => {
          console.log('ChatEvent in ChatApp: ', e);
  
          const isCurrentDialog = selectedDialogRef.current && selectedDialogRef.current.id === e.dialog_id;
  
          setDialogs(prevDialogs => {
            // Находим диалог, который нужно обновить
            const updatedDialog = prevDialogs.find(dialog => dialog.id === e.dialog_id);
            if (!updatedDialog) return prevDialogs;
  
            // Создаем обновленный диалог
            const newDialog = {
              ...updatedDialog,
              lastMessage: e.message || 'Файл',
              lastMessageTime: e.created_at,
              isNew: !isCurrentDialog
            };
  
            // Удаляем старый диалог из списка
            const filteredDialogs = prevDialogs.filter(dialog => dialog.id !== e.dialog_id);
  
            // Добавляем обновленный диалог в начало списка
            return [newDialog, ...filteredDialogs];
          });
  
          if (isCurrentDialog) {
            setMessages(prevMessages => [
              ...prevMessages,
              {
                id: e.id,
                message: e.message,
                created_at: e.created_at,
                user_id: e.user_id,
                client_id: e.client_id,
                viewed: e.viewed || false,
                file: e.file || null,
              }
            ]);
  
            // Отмечаем сообщение как просмотренное, если оно в текущем диалоге
            api.markMessagesAsViewed(selectedChannel.id, e.dialog_id).catch(error => {
              console.error('Ошибка при отметке сообщений как просмотренных:', error);
            });
          } else {
            setUnreadDialogs(prev => new Set(prev).add(e.dialog_id));
          }
        });
  
      echoInstance.private('chat_viewed')
        .listen('ChatViewedEvent', (e) => {
          if (e.channelId === selectedChannel?.id) {
            setMessages(prevMessages => 
              prevMessages.map(msg => ({ ...msg, viewed: true }))
            );
            setUnreadDialogs(prev => {
              const newSet = new Set(prev);
              newSet.delete(e.dialogId);
              return newSet;
            });
          }
        });
    };
  
    if (selectedChannel) {
      subscribeToChatEvents();
    }
  
    return () => {
      if (selectedChannel) {
        echoInstance.leave('chats');
        echoInstance.leave('chat_viewed');
      }
    };
  }, [selectedChannel]);

  return (
    <div className="flex h-[95%] w-full bg-base-200 rounded-lg overflow-hidden shadow-lg">
  <div className={`${selectedChannel ? 'hidden lg:block' : 'block'} ${isSidebarCollapsed ? 'lg:w-16' : 'w-full lg:w-1/4'}`}>
        {loadingChannels ? (
          <div className="flex items-center justify-center h-full">
            <span className="loading loading-spinner text-primary"></span>
          </div>
        ) : (
<Sidebar 
  channels={channels.map(channel => ({
    ...channel,
    bot_integration_id: channel.bot_integration_id || channel.id
  }))} 
  onSelectChannel={handleSelectChannel} 
  selectedChannelId={selectedChannel ? selectedChannel.id : null}
  onChannelUpdate={(channelId, updatedData) => {
    setChannels(prevChannels => prevChannels.map(channel => 
      channel.id === channelId ? { ...channel, ...updatedData } : channel
    ));
  }}
  isCollapsed={isSidebarCollapsed}
            onToggleCollapse={toggleSidebar}
          />
        )}
      </div>

      <div className={`${selectedDialog || !selectedChannel ? 'hidden lg:block' : 'block'} w-full bg-base-200 lg:w-1/4`}>
        {selectedChannel && (
          loadingDialogs ? (
            <div className="flex items-center justify-center h-full">
              <span className="loading loading-spinner text-primary"></span>
            </div>
          ) : (
            <ChatList 
              dialogs={dialogs} 
              onSelectDialog={handleSelectDialog} 
              onBack={handleBackToChannels} 
              selectedDialogId={selectedDialog ? selectedDialog.id : null}
              unreadDialogs={unreadDialogs}
            />
          )
        )}
      </div>

      <div className={`${selectedDialog ? 'block' : 'hidden lg:block'} lg:w-2/4 flex-grow bg-base-200`}>
        {selectedDialog && (
          loadingMessages ? (
            <div className="flex items-center justify-center h-full">
              <span className="loading loading-spinner text-primary"></span>
            </div>
          ) : (
            <ChatWindow
              messages={messages}
              onSendMessage={handleSendMessage}
              onBack={handleBackToDialogs}
              clientName={selectedDialog?.name}
              clientFirstName={selectedDialog?.client_first_name}
              clientLastName={selectedDialog?.client_last_name}
              selectedChannel={selectedChannel}
              selectedDialog={selectedDialog}
              scenarioStatus={scenarioStatus}
              onScenarioToggle={handleScenarioToggle}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ChatApp;